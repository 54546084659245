import { Box } from "@mui/material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import { TitleCard } from "@pimo/pimo-components";

import {
  ReportTitleCardEditSlot,
  type ReportTitleCardEditSlotProps,
} from "./report-title-card-edit-slot";
import {
  ReportTitleCardDashboardSlot,
  type ReportTitleCardDashboardSlotProps,
} from "./report-title-card-dashboard-slot";

type PropsWithoutEventHandler<T> = Omit<T, `on${string}`>;

export type ReportTitleCardProps = {
  title: string;
} & (
  | PropsWithoutEventHandler<ReportTitleCardEditSlotProps>
  | PropsWithoutEventHandler<ReportTitleCardDashboardSlotProps>
);

export const ReportTitleCard: PimoReactComponent<
  ReportTitleCardProps,
  "edit-button:click"
> = ({ fireEvent, title, ...props }) => (
  <TitleCard
    title={title}
    endSlot={
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {(props as ReportTitleCardEditSlotProps).members ? (
          <ReportTitleCardEditSlot
            {...(props as ReportTitleCardEditSlotProps)}
            onClick={() => fireEvent?.("edit-button:click")}
          />
        ) : (
          <ReportTitleCardDashboardSlot
            {...(props as ReportTitleCardDashboardSlotProps)}
          />
        )}
      </Box>
    }
  />
);
