import { Box } from "@mui/material";
import { DateBox } from "@pimo/pimo-components";
import {
  ReportingDateIndicator,
  type ReportingDateIndicatorProps,
} from "../reporting-date-indicator/reporting-date-indicator";

export type ReportTitleCardDashboardSlotProps = {
  endDate: string;
} & {
  reportingDateIndicator?: ReportingDateIndicatorProps;
  lastUpdated?: string;
};

export const ReportTitleCardDashboardSlot = ({
  endDate,
  reportingDateIndicator,
  lastUpdated,
}: ReportTitleCardDashboardSlotProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        gap: 2,
      }}
    >
      <DateBox label="Planned Completion Date:" date={endDate} />
      {lastUpdated && <DateBox label="Last update:" date={lastUpdated} />}
      {reportingDateIndicator && (
        <ReportingDateIndicator {...reportingDateIndicator} />
      )}
    </Box>
  );
};
