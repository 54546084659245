import {
  Route,
  View,
  type App,
  type Plugin,
  Table,
  TableDefinition,
  DeriveTableProps,
} from "@pimo/pimo-app-builder";
import {
  ArtefactsProgressCard,
  CategoriesWithValuesInBadgesCard,
  InformationCard,
  KeyInformationCard,
  LargeTextCard,
  LineGaugeCard,
  SectionHeader,
  StatusCard,
  TabLayout,
  TabsLayoutProps,
  TeamCard,
  RowLayout,
  ChipCell,
  TextCardCell,
  StatusIndicatorCell,
  STATUS_TYPE,
  CircularProgressCell,
  DateCell,
  IdentifierBadge,
  GridLayoutProps,
  IconCell,
  CombinedActivityStatusCard,
} from "@pimo/pimo-components";
import {
  ActivityAttributes,
  ActivityStatus,
  Attachment,
  DIMENSIONS,
  Dimension,
  EditDialogData,
  Maturity,
  OEProjectAPIAttributes,
  OverallTrendData,
  ProgramAPIAttributes,
  SingleStrapiResponse,
  StrapiCollectionEntry,
  TrendData,
  TrendFilter,
} from "iam-types";
import {
  areAllActivitiesCompleted,
  capitalize,
  determineArcherColorForMaturity,
  formatDate,
  formatDateTime,
  generateTrendEntriesFromCalculatedTrends,
  getChipAndTextColorForMaturityLevel,
  getFileIconFromFileType,
  getMaturityLevelNumberForMaturityLevel,
  getPercentageFromMaturityNumber,
  getReportingStatus,
  getStatusColorForActivityStatus,
  getTextColorAndIconForActivitsStatusDate,
  IAM_COLORS,
  isActivityOverdue,
  PROJECT_END_DATE,
  PROJECT_START_DATE,
  roundToTheNearestTenth,
  transformActivitiesToActivitiesGroupedBySubdimesion,
} from "iam-utils";

import { IAMAppState } from "../app";
import { ReportTitleCard } from "../../components/report-title-card/report-title-card";
import {
  fetchAllAttachmentsForProject,
  fetchOEProject,
  fetchProgram,
  fetchTrend,
  updateOeProjectAndRelatedActivities,
  uploadAttachments,
} from "../helpers/fetch-helper";
import { EditDialog } from "../../components/edit-dialog/edit-dialog";
import { StatusOfActivitiesTableContainer } from "../../components/status-of-activities-table/status-of-activites-table-container";
import { ReportAttachmentsTitleCard } from "../../components/attachments-title-card/attachments-title-card";
import { PopupWithTextContent } from "../../components/popup-with-text-content/popup-with-text-content";
import { STRAPI_URL } from "../env";
import { canUserAccessEditPage } from "../helpers/can-user-access-edit-page";
import {
  LineChartCard,
  LineChartCardEventMap,
} from "../../components/line-chart-card/line-chart-card";

type Viewname =
  | "dashboard"
  | "iam-gov"
  | "iam-model"
  | "iam-tech"
  | "attachments";

export interface ReportPluginPartialAppState {
  currentOEProject?: SingleStrapiResponse<
    StrapiCollectionEntry<OEProjectAPIAttributes>
  >;
  program?: SingleStrapiResponse<StrapiCollectionEntry<ProgramAPIAttributes>>;
  reportPluginPopupOpen?: boolean;
  reportPluginPopupContent?: StrapiCollectionEntry<ActivityAttributes>;
  attachments?: Attachment[];
  currentTrends?: {
    overall: TrendData;
    model: TrendData;
    gov: TrendData;
    tech: TrendData;
  };
  overallTrends?: {
    overall: OverallTrendData;
    model: OverallTrendData;
    gov: OverallTrendData;
    tech: OverallTrendData;
  };
  trendFilter?: {
    overall: TrendFilter;
    model: TrendFilter;
    gov: TrendFilter;
    tech: TrendFilter;
  };
  showDimensionCharts?: boolean;
}

export interface EditPagesAppState {
  editDialogData?: EditDialogData;
}

export class ReportPlugin
  implements
    Plugin<IAMAppState, ReportPluginPartialAppState, "reportId" | "viewname">
{
  route?: Route<"reportId" | "viewname", IAMAppState>;
  private reportView?: View<IAMAppState, TabsLayoutProps>;
  private app?: App<IAMAppState>;

  onRegister(app: App<IAMAppState>): void {
    this.app = app;
    const tabs: {
      name: string;
      viewname: Viewname;
      path: `/reports/:reportId/${Viewname}`;
    }[] = [
      {
        name: "Dashboard",
        viewname: "dashboard",
        path: "/reports/:reportId/dashboard",
      },
      {
        name: "IAM Model",
        viewname: "iam-model",
        path: "/reports/:reportId/iam-model",
      },
      {
        name: "IAM Governance",
        viewname: "iam-gov",
        path: "/reports/:reportId/iam-gov",
      },
      {
        name: "IAM Tech",
        viewname: "iam-tech",
        path: "/reports/:reportId/iam-tech",
      },
      {
        name: "Attachments",
        viewname: "attachments",
        path: "/reports/:reportId/attachments",
      },
    ];

    this.reportView = app.createView({
      name: "Reports",
      layout: new TabLayout(tabs, 1, [
        {
          icon: "pdf.svg",
          text: "PDF Export",
          onClick: () => {
            window.requestIdleCallback(() => window.print());
          },
        },
        {
          icon: "excel.svg",
          text: "Excel Export",
          onClick: () => {
            if (app.getAppState().currentOEProject?.data.id === undefined) {
              return;
            }
            const projectId = app.getAppState().currentOEProject?.data.id;
            /** linter still complains */
            window.open(`${STRAPI_URL}/api/export/${projectId ?? 1}`);
          },
        },
      ]),
    });

    for (const { viewname } of tabs) {
      if (viewname !== "dashboard" && viewname !== "attachments") {
        this.buildDimensionReportingView({ viewname });
      }
    }
    const view = this.reportView;
    this.buildDashboardView(view);
    this.buildAttachmentsView();
  }

  private buildAttachmentsView() {
    if (!this.reportView || !this.app) {
      return;
    }

    const titleComponent = this.reportView.addComponent({
      component: ReportAttachmentsTitleCard,
      layoutProps: { viewname: "attachments", xs: 12 },
    });

    titleComponent.mapState((state) => {
      const { endReportingDate, startReportingDate, reportingFrequency } =
        state.program?.data.attributes ?? {};
      const {
        endDate = "",
        startDate = "",
        createdAt = "",
        updatedAt = "",
      } = state.currentOEProject?.data.attributes ?? {};

      const title = "Attachments";

      return {
        endDate,
        title,
        startDate,
        reportingDateIndicator: getReportingStatus({
          lastUpdatedDateInStringFormat: updatedAt ?? createdAt,
          endReportingDateInStringFormat: endReportingDate,
          startReportingDateInStringFormat: startReportingDate,
          reportingFrequency,
          conditionUponWhichToReturnGreenByDefault: areAllActivitiesCompleted(
            state.currentOEProject?.data.attributes.activities.data ?? []
          ),
        }),
        showUploadButton: canUserAccessEditPage(
          state.userProfile,
          state.currentOEProject?.data
        ),
      };
    });

    titleComponent.on("upload-button:click", async ({ payload }) => {
      if (!payload) {
        return;
      }
      await uploadAttachments(
        payload,
        (this.app?.getAppState().currentOEProject?.data?.id ?? 0).toString()
      );
      const attachments = await fetchAllAttachmentsForProject(
        (this.app?.getAppState().currentOEProject?.data?.id ?? 0).toString()
      );
      this?.app?.setAppState({ ...this?.app?.getAppState(), attachments });
    });

    const tableDefinition: TableDefinition = [
      { component: IconCell },
      { component: TextCardCell },
      { component: TextCardCell },
      { component: DateCell },
    ] as const;
    const table = new Table(tableDefinition, "report");
    const attachmentsTable = this.reportView.addComponent<
      DeriveTableProps<typeof table>,
      unknown,
      unknown
    >({
      component: table,
      layoutProps: {
        viewname: "attachments",
        xs: 12,
      },
    });

    attachmentsTable.mapState((state) => {
      const attachments = state?.attachments ?? [];

      return {
        data:
          attachments.map(
            (item): DeriveTableProps<typeof table>["data"][number] => {
              return {
                rowProps: {
                  cardProps: { sx: { cursor: "pointer" } },
                  onClick: () => {
                    window.open(`${STRAPI_URL}${item.url}`, "_blank");
                  },
                },
                columnProps: [
                  {
                    value: getFileIconFromFileType(item.ext),
                  },
                  {
                    body: item.name,
                    bodyProps: {
                      sx: {
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        minWidth: 400,
                        textDecoration: "underline",
                      },
                    },
                  },
                  {
                    body: item.caption,
                    bodyProps: { sx: { minWidth: 200, maxWidth: 200 } },
                  },

                  {
                    date: formatDate(item.createdAt),
                    icon: "calendar-outlined.svg",
                    cardProps: {
                      sx: {
                        color: "inherit",
                        height: "auto",
                      },
                    },
                  },
                ],
              };
            }
          ) ?? [],
        tableHeaderEntries: ["Type", "Name", "Author", "Upload Date"],
      };
    });
  }

  buildDashboardView(
    view: View<IAMAppState, GridLayoutProps | TabsLayoutProps>,
    id?: number
  ) {
    if (!this.app || !view) {
      return;
    }
    const titleComponent = view.addComponent({
      component: ReportTitleCard,
      layoutProps: { viewname: "dashboard", xs: 12 },
    });

    titleComponent.mapState((state) => {
      const { startReportingDate, endReportingDate, reportingFrequency } =
        state.program?.data.attributes ?? {};
      const project =
        state.oeProjects?.find((project) => project.id === id)?.attributes ??
        state.currentOEProject?.data.attributes ??
        ({} as StrapiCollectionEntry<OEProjectAPIAttributes>["attributes"]);
      const {
        overallCompletionDateBasedOnActivities = "",
        name = "",
        oeEarliestUpdatedAt = "",
        oeEarliestUpdatedBy,
      } = project;
      const formattedOverallCompletionDateBasedOnActivities = formatDate(
        overallCompletionDateBasedOnActivities
      );
      const formattedReportingDate = formatDate(endReportingDate ?? "");
      const title = [
        "Dashboard",
        [name, formattedReportingDate && `(as of ${formattedReportingDate})`]
          .filter(Boolean)
          .join(" "),
      ].join(" - ");
      const onHoverText = oeEarliestUpdatedAt
        ? `as of ${formatDateTime(oeEarliestUpdatedAt)} ${
            oeEarliestUpdatedBy ? `by ${oeEarliestUpdatedBy}` : ""
          }`
        : "";
      const lastUpdated = formatDate(oeEarliestUpdatedAt);

      return {
        endDate: formattedOverallCompletionDateBasedOnActivities,
        title,
        reportingDateIndicator: {
          ...getReportingStatus({
            lastUpdatedDateInStringFormat: oeEarliestUpdatedAt,
            endReportingDateInStringFormat: endReportingDate,
            startReportingDateInStringFormat: startReportingDate,
            reportingFrequency,
            conditionUponWhichToReturnGreenByDefault: areAllActivitiesCompleted(
              project.activities?.data ?? []
            ),
          }),
          onHoverText,
        },
        lastUpdated,
      };
    });

    const overallMaturityTitle = view.addComponent({
      component: SectionHeader,
      layoutProps: {
        viewname: "dashboard",
        xs: 12,
      },
    });

    overallMaturityTitle.mapState((state) => {
      const project =
        state.oeProjects?.find((project) => project.id === id)?.attributes ??
        state.currentOEProject?.data.attributes ??
        ({} as StrapiCollectionEntry<OEProjectAPIAttributes>["attributes"]);

      const overallAverageMaturityLevel =
        project.overallAverageMaturityLevel ?? 0;

      const { colorScheme, maturity } = getChipAndTextColorForMaturityLevel(
        overallAverageMaturityLevel
      );
      const { colorScheme: colorSchemeArcherColorCoding } =
        determineArcherColorForMaturity(overallAverageMaturityLevel);
      const maturityWithoutNumber = maturity.replace(/ \(\d\)$/, "");

      return {
        title: `Overall Maturity (reported as Information Security Health Indicator):`,
        badgeTextContent: `${maturityWithoutNumber} (${overallAverageMaturityLevel.toFixed(
          1
        )})`,
        circleColor: colorSchemeArcherColorCoding.chipColor,
        endSlotChipDetails: {
          value: `${
            state.currentOEProject?.data.attributes._calculated?.rank ?? 0
          }/${
            state.currentOEProject?.data.attributes._calculated
              ?.totalNumberOfOes ?? 0
          }`,
          label: "OE Ranking",
        },
        sx: {
          "@media print": {
            pageBreakBefore: "avoid",
          },
        },
        ...colorScheme,
      };
    });
    for (const dimension of DIMENSIONS) {
      const gaugeCard = view.addComponent({
        component: LineGaugeCard,
        layoutProps: {
          viewname: "dashboard",
          xs: 4,
        },
      });

      gaugeCard.mapState((state) => {
        const project =
          state.oeProjects?.find((project) => project.id === id)?.attributes ??
          state.currentOEProject?.data.attributes ??
          ({} as StrapiCollectionEntry<OEProjectAPIAttributes>["attributes"]);

        const completionDate =
          project?.completionDatePerDimension?.[dimension]?.completionDate;

        return {
          title: `Maturity - ${dimension}`,
          startDate: "",
          showStartDate: false,
          endDate: completionDate ? formatDate(completionDate) : "n/a",
          entries: [
            {
              label: "Average",
              isTitle: true,
              percentage: getPercentageFromMaturityNumber(
                project?.maturityLevelPerDimensionAndSubdimension?.[dimension]
                  .averageMaturityNumber ?? 5
              ),
              value:
                project?.maturityLevelPerDimensionAndSubdimension?.[dimension]
                  .averageMaturityNumber ?? 5,
            },
            ...(project?.maturityLevelPerDimensionAndSubdimension?.[
              dimension
            ].perSubdimension.map((entry) => ({
              label: entry.subdimension,
              percentage: getPercentageFromMaturityNumber(entry.maturityNumber),
              value: entry.maturityNumber,
            })) ?? []),
          ],
          scale: [1, 2, 3, 4, 5],
        };
      });
    }

    const oeProgressLineChart = view.addComponent({
      component: LineChartCard,
      layoutProps: { viewname: "dashboard", xs: 12 },
    });

    oeProgressLineChart.mapState((state) => {
      const { actuals, planned, recommended } =
        generateTrendEntriesFromCalculatedTrends({
          scale: "monthly",
          year: new Date().getFullYear(),
          calculatedActuals: state.overallTrends?.overall.actuals ?? [],
          calculatedPlanned: state.overallTrends?.overall.planned ?? [],
          calculatedRecommended: state.overallTrends?.tech.recommended ?? [],
        });
      return {
        canToggleVisibilty: true,
        currentScale: state.trendFilter?.overall.trendScale ?? "monthly",
        currentYear:
          state.trendFilter?.overall.trendYear ?? new Date().getFullYear(),
        endDate: PROJECT_END_DATE,
        title: "Trend OE Level",
        startDate: PROJECT_START_DATE,
        series: [
          {
            id: "actual",
            label: "Actual",
            data:
              state.currentTrends?.overall.actuals.map((val) =>
                val ? roundToTheNearestTenth(val) : val
              ) ??
              actuals.map((val) => (val ? roundToTheNearestTenth(val) : val)),
            curve: "linear",
          },
          {
            id: "planned",
            label: "Planned",
            data:
              state.currentTrends?.overall.planned.map((val) =>
                val ? roundToTheNearestTenth(val) : val
              ) ??
              planned.map((val) => (val ? roundToTheNearestTenth(val) : val)),
            curve: "linear",
          },
          {
            id: "recommended",
            label: "Minimum Target",
            data:
              state.currentTrends?.overall.recommended?.map((val) =>
                val ? roundToTheNearestTenth(val) : val
              ) ??
              recommended.map((val) =>
                val ? roundToTheNearestTenth(val) : val
              ),
            curve: "linear",
            connectNulls: true,
            /** index equal to 10 because in case of monthly only November (the 10 month) should have a dot */
            showMark: ({ index }) =>
              state.trendFilter?.overall.trendScale === "monthly"
                ? index === 10
                : true,
          },
        ],
      };
    });

    oeProgressLineChart.on("change-time", (event) => {
      const payload = event.payload as LineChartCardEventMap["change-time"];

      const state = this.app?.getAppState();

      if (!payload || !state || !payload.scale || !payload.year) {
        return;
      }

      this.app?.setAppState({
        ...state,
        trendFilter: {
          ...(state.trendFilter ?? {
            model: {
              trendScale: "monthly",
              trendYear: new Date().getFullYear(),
            },
            tech: {
              trendScale: "monthly",
              trendYear: new Date().getFullYear(),
            },
            gov: { trendScale: "monthly", trendYear: new Date().getFullYear() },
          }),

          overall: { trendScale: payload.scale, trendYear: payload.year },
        },

        currentTrends: {
          ...(state.currentTrends ?? {
            model: { actuals: [], planned: [], recommended: [] },
            gov: { actuals: [], planned: [], recommended: [] },
            tech: { actuals: [], planned: [], recommended: [] },
          }),
          overall: generateTrendEntriesFromCalculatedTrends({
            scale: payload.scale,
            year: payload.year,
            calculatedActuals: state.overallTrends?.overall.actuals ?? [],
            calculatedPlanned: state.overallTrends?.overall.planned ?? [],
            calculatedRecommended: state.overallTrends?.tech.recommended ?? [],
          }),
        },
      });
    });

    const modelLineChart = view.addComponent({
      component: LineChartCard,
      layoutProps: { viewname: "dashboard", xs: 4 },
    });

    modelLineChart.mapState((state) => {
      const { actuals, planned, recommended } =
        generateTrendEntriesFromCalculatedTrends({
          scale: "monthly",
          year: new Date().getFullYear(),
          calculatedActuals: state.overallTrends?.model.actuals ?? [],
          calculatedPlanned: state.overallTrends?.model.planned ?? [],
          calculatedRecommended: state.overallTrends?.tech.recommended ?? [],
        });
      return {
        title: "IAM Model Trends",
        currentScale: state.trendFilter?.model.trendScale ?? "monthly",
        currentYear:
          state.trendFilter?.model.trendYear ?? new Date().getFullYear(),
        startDate: PROJECT_START_DATE,
        endDate: PROJECT_END_DATE,
        series: [
          {
            id: "actual",
            label: "Actual",
            data:
              state.currentTrends?.model.actuals.map((val) =>
                val ? roundToTheNearestTenth(val) : val
              ) ??
              actuals.map((val) => (val ? roundToTheNearestTenth(val) : val)),
            curve: "linear",
          },
          {
            id: "planned",
            label: "Planned",
            data:
              state.currentTrends?.model.planned.map((val) =>
                val ? roundToTheNearestTenth(val) : val
              ) ??
              planned.map((val) => (val ? roundToTheNearestTenth(val) : val)),
            curve: "linear",
          },
          {
            id: "recommended",
            label: "Minimum Target",
            data:
              state.currentTrends?.model.recommended?.map((val) =>
                val ? roundToTheNearestTenth(val) : val
              ) ??
              recommended.map((val) =>
                val ? roundToTheNearestTenth(val) : val
              ),
            curve: "linear",
            connectNulls: true,
            /** index equal to 10 because in case of monthly only November (the 10 month) should have a dot */
            showMark: ({ index }) =>
              state.trendFilter?.model.trendScale === "monthly"
                ? index === 10
                : true,
          },
        ],
      };
    });

    modelLineChart.on("change-time", (event) => {
      const payload = event.payload as LineChartCardEventMap["change-time"];
      const state = this.app?.getAppState();

      if (!payload || !state || !payload.scale || !payload.year) {
        return;
      }

      this.app?.setAppState({
        ...state,
        trendFilter: {
          ...(state.trendFilter ?? {
            tech: {
              trendScale: "monthly",
              trendYear: new Date().getFullYear(),
            },
            overall: {
              trendScale: "monthly",
              trendYear: new Date().getFullYear(),
            },
            gov: { trendScale: "monthly", trendYear: new Date().getFullYear() },
          }),

          model: { trendScale: payload.scale, trendYear: payload.year },
        },
        currentTrends: {
          ...(state.currentTrends ?? {
            overall: { actuals: [], planned: [], recommended: [] },
            gov: { actuals: [], planned: [], recommended: [] },
            tech: { actuals: [], planned: [], recommended: [] },
          }),
          model: generateTrendEntriesFromCalculatedTrends({
            scale: payload.scale,
            year: payload.year,
            calculatedActuals: state.overallTrends?.model.actuals ?? [],
            calculatedPlanned: state.overallTrends?.model.planned ?? [],
            calculatedRecommended: state.overallTrends?.tech.recommended ?? [],
          }),
        },
      });
    });

    const govLineChart = view.addComponent({
      component: LineChartCard,
      layoutProps: { viewname: "dashboard", xs: 4 },
    });

    govLineChart.mapState((state) => {
      const { actuals, planned, recommended } =
        generateTrendEntriesFromCalculatedTrends({
          scale: "monthly",
          year: new Date().getFullYear(),
          calculatedActuals: state.overallTrends?.gov.actuals ?? [],
          calculatedPlanned: state.overallTrends?.gov.planned ?? [],
          calculatedRecommended: state.overallTrends?.tech.recommended ?? [],
        });
      return {
        title: "IAM Governance Trends",
        currentScale: state.trendFilter?.gov.trendScale ?? "monthly",
        currentYear:
          state.trendFilter?.gov.trendYear ?? new Date().getFullYear(),
        startDate: PROJECT_START_DATE,
        endDate: PROJECT_END_DATE,
        series: [
          {
            id: "actual",
            label: "Actual",
            data:
              state.currentTrends?.gov.actuals.map((val) =>
                val ? roundToTheNearestTenth(val) : val
              ) ??
              actuals.map((val) => (val ? roundToTheNearestTenth(val) : val)),
            curve: "linear",
          },
          {
            id: "planned",
            label: "Planned",
            data:
              state.currentTrends?.gov.planned.map((val) =>
                val ? roundToTheNearestTenth(val) : val
              ) ??
              planned.map((val) => (val ? roundToTheNearestTenth(val) : val)),
            curve: "linear",
          },
          {
            id: "recommended",
            label: "Minimum Target",
            data:
              state.currentTrends?.gov.recommended?.map((val) =>
                val ? roundToTheNearestTenth(val) : val
              ) ??
              recommended.map((val) =>
                val ? roundToTheNearestTenth(val) : val
              ),
            curve: "linear",
            connectNulls: true,
            /** index equal to 10 because in case of monthly only November (the 10 month) should have a dot */
            showMark: ({ index }) =>
              state.trendFilter?.gov.trendScale === "monthly"
                ? index === 10
                : true,
          },
        ],
      };
    });

    govLineChart.on("change-time", (event) => {
      const payload = event.payload as LineChartCardEventMap["change-time"];
      const state = this.app?.getAppState();

      if (!payload || !state || !payload.scale || !payload.year) {
        return;
      }
      this.app?.setAppState({
        ...state,
        trendFilter: {
          ...(state.trendFilter ?? {
            model: {
              trendScale: "monthly",
              trendYear: new Date().getFullYear(),
            },
            overall: {
              trendScale: "monthly",
              trendYear: new Date().getFullYear(),
            },
            tech: {
              trendScale: "monthly",
              trendYear: new Date().getFullYear(),
            },
          }),

          gov: { trendScale: payload.scale, trendYear: payload.year },
        },
        currentTrends: {
          ...(state.currentTrends ?? {
            overall: { actuals: [], planned: [], recommended: [] },
            model: { actuals: [], planned: [], recommended: [] },
            tech: { actuals: [], planned: [], recommended: [] },
          }),
          gov: generateTrendEntriesFromCalculatedTrends({
            scale: payload.scale,
            year: payload.year,
            calculatedActuals: state.overallTrends?.gov.actuals ?? [],
            calculatedPlanned: state.overallTrends?.gov.planned ?? [],
            calculatedRecommended: state.overallTrends?.tech.recommended ?? [],
          }),
        },
      });
    });

    const techLineChart = view.addComponent({
      component: LineChartCard,
      layoutProps: { viewname: "dashboard", xs: 4 },
    });

    techLineChart.mapState((state) => {
      const { actuals, planned, recommended } =
        generateTrendEntriesFromCalculatedTrends({
          scale: "monthly",
          year: new Date().getFullYear(),
          calculatedActuals: state.overallTrends?.tech.actuals ?? [],
          calculatedPlanned: state.overallTrends?.tech.planned ?? [],
          calculatedRecommended: state.overallTrends?.tech.recommended ?? [],
        });
      return {
        title: "IAM Technology Trends",
        currentScale: state.trendFilter?.tech.trendScale ?? "monthly",
        currentYear:
          state.trendFilter?.tech.trendYear ?? new Date().getFullYear(),
        startDate: PROJECT_START_DATE,
        endDate: PROJECT_END_DATE,
        series: [
          {
            id: "actual",
            label: "Actual",
            data:
              state.currentTrends?.tech.actuals.map((val) =>
                val ? roundToTheNearestTenth(val) : val
              ) ??
              actuals.map((val) => (val ? roundToTheNearestTenth(val) : val)),
            curve: "linear",
          },
          {
            id: "planned",
            label: "Planned",
            data:
              state.currentTrends?.tech.planned.map((val) =>
                val ? roundToTheNearestTenth(val) : val
              ) ??
              planned.map((val) => (val ? roundToTheNearestTenth(val) : val)),
            curve: "linear",
          },
          {
            id: "recommended",
            label: "Minimum Target",
            data:
              state.currentTrends?.tech.recommended?.map((val) =>
                val ? roundToTheNearestTenth(val) : val
              ) ??
              recommended.map((val) =>
                val ? roundToTheNearestTenth(val) : val
              ),
            connectNulls: true,
            curve: "linear",
            /** index equal to 10 because in case of monthly only November (the 10 month) should have a dot */
            showMark: ({ index }) =>
              state.trendFilter?.tech.trendScale === "monthly"
                ? index === 10
                : true,
          },
        ],
      };
    });

    techLineChart.on("change-time", (event) => {
      const payload = event.payload as LineChartCardEventMap["change-time"];
      const state = this.app?.getAppState();

      if (!payload || !state || !payload.scale || !payload.year) {
        return;
      }
      this.app?.setAppState({
        ...state,
        trendFilter: {
          ...(state.trendFilter ?? {
            model: {
              trendScale: "monthly",
              trendYear: new Date().getFullYear(),
            },
            overall: {
              trendScale: "monthly",
              trendYear: new Date().getFullYear(),
            },
            gov: { trendScale: "monthly", trendYear: new Date().getFullYear() },
          }),
          tech: { trendScale: payload.scale, trendYear: payload.year },
        },
        currentTrends: {
          ...(state.currentTrends ?? {
            overall: { actuals: [], planned: [], recommended: [] },
            gov: { actuals: [], planned: [], recommended: [] },
            model: { actuals: [], planned: [], recommended: [] },
          }),
          tech: generateTrendEntriesFromCalculatedTrends({
            scale: payload.scale,
            year: payload.year,
            calculatedActuals: state.overallTrends?.tech.actuals ?? [],
            calculatedPlanned: state.overallTrends?.tech.planned ?? [],
            calculatedRecommended: state.overallTrends?.tech.recommended ?? [],
          }),
        },
      });
    });
    oeProgressLineChart.on("toggle-dimensions", (event) => {
      const payload =
        event.payload as LineChartCardEventMap["toggle-dimensions"];

      const state = this.app?.getAppState();

      if (!payload || !state) {
        return;
      }
      this.app?.setAppState({
        ...this.app.getAppState(),
        showDimensionCharts: payload.showDimensions,
      });
    });
    govLineChart.mapVisibility((state) => {
      if (!state) {
        return false;
      }

      return state.showDimensionCharts ?? false;
    });

    modelLineChart.mapVisibility((state) => {
      if (!state) {
        return false;
      }

      return state.showDimensionCharts ?? false;
    });

    techLineChart.mapVisibility((state) => {
      if (!state) {
        return false;
      }

      return state.showDimensionCharts ?? false;
    });

    const overallActivitiesTitle = view.addComponent({
      component: SectionHeader,
      layoutProps: {
        viewname: "dashboard",
        xs: 12,
      },
    });

    overallActivitiesTitle.mapState((state) => {
      const project =
        state.oeProjects?.find((project) => project.id === id)?.attributes ??
        state.currentOEProject?.data.attributes ??
        ({} as StrapiCollectionEntry<OEProjectAPIAttributes>["attributes"]);

      const dimensions = project.completedActivitiesPerDimensionAndSubDimension;
      const { completed, overall } = Object.values(dimensions ?? {}).reduce(
        (result, { completed, overall }) => ({
          completed: result.completed + completed,
          overall: result.overall + overall,
        }),
        { completed: 0, overall: 0 }
      );
      const percentage = Math.round((completed / overall) * 100);

      return {
        title: `Overall Activities:`,
        chipColor: IAM_COLORS.babyBlue,
        textColor: "#fff",
        badgeTextContent: `${completed}/${overall} (${
          isNaN(percentage) ? 0 : percentage
        } %)`,
      };
    });
    const rollOutOverviewCard = view.addComponent({
      component: InformationCard,
      layoutProps: {
        viewname: "dashboard",
        xs: 6,
      },
    });

    rollOutOverviewCard.mapState((state) => {
      const project =
        state.oeProjects?.find((project) => project.id === id)?.attributes ??
        state.currentOEProject?.data.attributes ??
        ({} as StrapiCollectionEntry<OEProjectAPIAttributes>["attributes"]);

      const {
        percentageOfCompletedActivities,
        numberOfCompletedActivities,
        numberOfActivities,
      } = project ?? {};

      return {
        title: "Roll Out Overview - Completed Activities",
        progressBars: [
          {
            title: "Total",
            progress: percentageOfCompletedActivities ?? 0,
            actualValue: `${numberOfCompletedActivities ?? 0}/${
              numberOfActivities ?? 0
            } (${Math.round(percentageOfCompletedActivities ?? 0)} %)`,
          },
          ...DIMENSIONS.map((dimension) => {
            const entryForDimension =
              project.completedActivitiesPerDimensionAndSubDimension?.[
                dimension
              ];
            return {
              title: dimension,
              progress: entryForDimension?.percentageCompleted ?? 0,
              actualValue: `${entryForDimension?.completed ?? 0} / ${
                entryForDimension?.overall ?? 0
              } (${Math.round(entryForDimension?.percentageCompleted ?? 0)} %)`,
            };
          }),
        ],
      };
    });
    const combinedActivityStatusCard = view.addComponent({
      component: CombinedActivityStatusCard,
      layoutProps: {
        viewname: "dashboard",
        xs: 6,
      },
    });

    combinedActivityStatusCard.mapState((state) => {
      const project =
        state.oeProjects?.find((project) => project.id === id)?.attributes ??
        state.currentOEProject?.data.attributes ??
        ({} as StrapiCollectionEntry<OEProjectAPIAttributes>["attributes"]);

      const numberOfActivitiesPerStatus =
        project.numberOfActivitiesPerStatus ?? {
          "at risk": 0,
          "not started": 0,
          "on track": 0,
          completed: 0,
        };

      const numberOfOverdueActivities = project.numberOfOverdueActivities ?? 0;

      const activityProgress = project.overallAverageActivityProgress ?? 0;

      const statusesToDisplay: ActivityStatus[] = [
        "not started",
        "at risk",
        "on track",
        "completed",
      ];

      return {
        donutChartProps: {
          cardProps: {
            sx: {
              flex: 1,
            },
          },
          title: "Status of Activities",
          series: statusesToDisplay.map(
            (status) => numberOfActivitiesPerStatus[status]
          ),
          backgroundColor: ["#eeeeee", "#FAB600", "#00C853", "#003781"],
          legendIcons: ["", "", "", `checkmark-squared-outlined.svg`],
          legendPosition: "left",
          labels: statusesToDisplay.map((status) => capitalize(status)),
          toolTipLabels: statusesToDisplay.map((status) => capitalize(status)),
          toolTipEnabled: true,
        },

        numberCardProps: {
          iconSrc: "calendar.svg",
          number: numberOfOverdueActivities,
          title: "Activities Overdue",
        },
        progressCardProps: {
          cardTitle: "Activities",
          cardIcon: "activities.svg",
          color: IAM_COLORS.babyBlue,
          value: activityProgress,
          displayValue: `${Math.round(activityProgress)} %`,
          label: "Progress",
        },
      };
    });

    const projectInformationTitle = view.addComponent({
      component: SectionHeader,
      layoutProps: {
        viewname: "dashboard",
        xs: 12,
      },
    });

    projectInformationTitle.mapState(() => ({ title: "Project Information" }));

    const keyInformation = view.addComponent({
      component: KeyInformationCard,
      layoutProps: {
        viewname: "dashboard",
        xs: 6,
      },
    });

    keyInformation.mapState((state) => {
      const project =
        state.oeProjects?.find((project) => project.id === id)?.attributes ??
        state.currentOEProject?.data.attributes ??
        ({} as StrapiCollectionEntry<OEProjectAPIAttributes>["attributes"]);

      let localIAMTool: string | undefined = project.localIAMTool;

      if (localIAMTool === "Other" && project.otherLocalIAMTool) {
        localIAMTool = project.otherLocalIAMTool;
      }

      if (!localIAMTool) {
        localIAMTool = "n/a";
      }

      const {
        kpiLocalITAssetsOnboardedToIAMTooling,
        kpiNumberOfAuthorizationConceptsForITAssets,
        kpiOEIAMGovernance,
        kpiOEIAMBusinessOperations,
        kpiIAMChangeBudget,
        kpiNumberOfLocalITAssets,
        businessRoleManagementTool,
      } = project ?? {};

      return {
        title: "Key Information",
        entries: [
          {
            label: "Business Role Management Tool",
            value: businessRoleManagementTool ? "Yes" : "No",
          },
          {
            label: "OE IAM Governance (#FTE)",
            value: kpiOEIAMGovernance ?? "n/a",
          },
          {
            label: "OE IAM Business Operations (#FTE)",
            value: kpiOEIAMBusinessOperations ?? "n/a",
          },
          {
            label: "IAM Change Budget (€ p.a.)",
            value: kpiIAMChangeBudget?.toLocaleString() ?? "n/a",
          },
          {
            label: "Local IAM Tool (name)",
            value: localIAMTool,
          },
          {
            label: "# of Local IT Assets",
            value: kpiNumberOfLocalITAssets ?? 0,
          },
          {
            label: "# of Local IT Assets Onboarded to IAM Tooling",
            value:
              kpiLocalITAssetsOnboardedToIAMTooling != null
                ? `${kpiLocalITAssetsOnboardedToIAMTooling}`
                : "n/a",
          },
          {
            label:
              "% of Local IT Assets that have an Authorization Concept which is regularly reviewed and updated",
            value:
              kpiNumberOfAuthorizationConceptsForITAssets != null
                ? `${kpiNumberOfAuthorizationConceptsForITAssets}`
                : "n/a",
          },
        ],
      };
    });

    const teamCard = view.addComponent({
      component: TeamCard,
      layoutProps: {
        viewname: "dashboard",
        xs: 6,
      },
    });

    teamCard.mapState((state) => {
      const project =
        state.oeProjects?.find((project) => project.id === id)?.attributes ??
        state.currentOEProject?.data.attributes ??
        ({} as StrapiCollectionEntry<OEProjectAPIAttributes>["attributes"]);
      const { team } = project ?? {};

      return {
        team: {
          teamMembers:
            team
              ?.filter(
                (member) =>
                  member.role?.data?.attributes.name &&
                  member.person?.data?.attributes.name
              )
              .map((teamMember) => {
                const { name } = teamMember?.person?.data?.attributes ?? {};
                const { name: roleName } =
                  teamMember?.role?.data?.attributes ?? {};

                return {
                  fullName: name ?? "n/a",
                  role: roleName ?? "n/a",
                  chipColor: IAM_COLORS.babyBlue,
                };
              }) ?? [],
        },
        title: "IAM Functions",
      };
    });
  }

  private buildStatusOfActivitiesTable(viewname: Viewname) {
    if (!this.reportView || !this.app) {
      return;
    }

    const popup = this.reportView.addComponent({
      component: PopupWithTextContent,
      layoutProps: { viewname, xs: 12 },
    });

    popup.mapState((state) => {
      return {
        open: state?.reportPluginPopupOpen ?? false,
        sectionsToDisplay: [
          {
            title: "Activity",
            text: state.reportPluginPopupContent?.attributes?.description ?? "",
          },
          {
            title: "Comment",
            text: state.reportPluginPopupContent?.attributes?.comment ?? "",
          },
        ],
        cardTitle: `Details on ${
          state.reportPluginPopupContent?.attributes?.activityId ?? ""
        }`,
      };
    });

    popup.on("popup:open", (payload) => {
      const state = this.app?.getAppState();
      if (!payload || !state) {
        return;
      }

      this.app?.setAppState({
        ...state,
        reportPluginPopupContent: payload.payload,
        reportPluginPopupOpen: true,
      });
    });
    popup.on("popup:close", () => {
      const state = this.app?.getAppState();
      if (!state) {
        return;
      }

      this.app?.setAppState({
        ...state,
        reportPluginPopupOpen: false,
      });
    });
    const dimension = this.getDimensionForViewname(viewname);
    const tableDefinition: TableDefinition = [
      { component: IdentifierBadge },
      { component: ChipCell },
      { component: TextCardCell },
      { component: TextCardCell },
      { component: StatusIndicatorCell },
      { component: CircularProgressCell },
      { component: DateCell },
      { component: DateCell },
    ] as const;
    const table = new Table(tableDefinition, "grouped-report");
    const statusOfActivitiesTable = this.reportView.addComponent<
      DeriveTableProps<typeof table>,
      unknown,
      unknown
    >({
      component: table,
      layoutProps: {
        viewname,
        xs: 12,
      },
    });

    statusOfActivitiesTable.mapState((state) => {
      /**
       * for IAM Model there is a fixed order of the subdimensions in the table
       * BRT is first then processes and the controls, hence the custom sorting
       */
      let items: StrapiCollectionEntry<ActivityAttributes>[] = [];
      if (dimension === "IAM Model") {
        customSort(
          (items = (
            (
              state.currentOEProject?.data.attributes
                .activities as unknown as SingleStrapiResponse<
                StrapiCollectionEntry<ActivityAttributes>[]
              >
            )?.data ?? []
          ).filter((item) => item.attributes?.dimension === dimension))
        );
      } else {
        items = (
          (
            state.currentOEProject?.data.attributes
              .activities as unknown as SingleStrapiResponse<
              StrapiCollectionEntry<ActivityAttributes>[]
            >
          )?.data ?? []
        ).filter((item) => item.attributes?.dimension === dimension);
      }

      return {
        container: StatusOfActivitiesTableContainer,
        data: items.map(
          (item): DeriveTableProps<typeof table>["data"][number] => {
            const {
              activityId,
              description,
              completionDate,
              owner,
              progress,
              status,
              actualCompletionDate = "",
              startDate = "",
            } = item?.attributes ?? {};
            const { colorScheme, maturity } =
              getChipAndTextColorForMaturityLevel(
                getMaturityLevelNumberForMaturityLevel(
                  item?.attributes?.maturity ?? "very low 1"
                )
              );
            const formattedActualCompletionDate =
              formatDate(actualCompletionDate);
            const overdue = isActivityOverdue(item.attributes);
            const maturityLevelPerSubdimension =
              state.currentOEProject?.data.attributes?.maturityLevelPerDimensionAndSubdimension?.[
                dimension
              ]?.perSubdimension?.find(
                ({ subdimension }) =>
                  subdimension === item.attributes.subdimension
              );
            const dimensionLatestCompletionDate =
              this.determineLatestCompletionDateFromSubDimension(item, state);
            const { icon, color, textOnHover } =
              getTextColorAndIconForActivitsStatusDate(
                overdue,
                completionDate,
                dimensionLatestCompletionDate
              );

            const chipAndTextColorForSubdimensionMaturity =
              getChipAndTextColorForMaturityLevel(
                getMaturityLevelNumberForMaturityLevel(
                  maturityLevelPerSubdimension?.maturity ?? "very low 1"
                )
              );
            return {
              rowProps: {
                group: item.attributes.subdimension
                  ? `${item.attributes.subdimension} - Maturity: ${
                      capitalize(
                        maturityLevelPerSubdimension?.maturity ?? ""
                      ) as Maturity
                    } `
                  : "",
                chip: {
                  text: maturityLevelPerSubdimension?.maturity ?? "",
                  color:
                    chipAndTextColorForSubdimensionMaturity.colorScheme
                      .chipColor,
                },
                onClick: () => {
                  popup.fireEvent("popup:open", item);
                },
                cardProps: { sx: { cursor: "pointer" } },
              },
              columnProps: [
                {
                  chipColor: IAM_COLORS.cyanBlue,
                  children: activityId,
                  chipWidth: 75,
                },
                {
                  body: maturity,
                  chipProps: {
                    sx: {
                      borderRadius: "12px",
                      backgroundColor: colorScheme.chipColor,
                      color: colorScheme.textColor,
                      maxWidth: 120,
                      minWidth: 120,
                    },
                  },
                },
                {
                  body: description,

                  bodyProps: {
                    sx: {
                      display: "-webkit-box",
                      overflow: "hidden",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                      minWidth: 400,
                    },
                  },
                },
                {
                  body: owner,
                  bodyProps: { sx: { minWidth: 150, maxWidth: 150 } },
                },
                {
                  body: status || "not started",
                  textOnHover: formattedActualCompletionDate
                    ? `as of ${formattedActualCompletionDate}`
                    : "",
                  status: getStatusColorForActivityStatus(
                    status || "not started"
                  ),
                  sx: {
                    borderRadius: status === "completed" ? null : "50%",
                  },
                },
                {
                  value: progress ?? 0,
                },
                {
                  date: formatDate(startDate ?? ""),
                  icon: "calendar-outlined.svg",
                  cardProps: {
                    sx: {
                      height: "auto",
                    },
                  },
                },
                {
                  date: formatDate(completionDate ?? ""),
                  icon: icon,
                  cardProps: {
                    sx: {
                      color: color,
                      height: "auto",
                    },
                  },
                  textOnHover: textOnHover,
                },
              ],
            };
          }
        ),
        tableHeaderEntries: [
          "Id",
          "Maturity",
          "Activity",
          "Owner",
          "Status",
          "Progress",
          "Start date",
          "Planned Completion Date",
        ],
      };
    });
  }

  private determineLatestCompletionDateFromSubDimension(
    item: StrapiCollectionEntry<ActivityAttributes>,
    state: IAMAppState
  ) {
    let dimensionLatestCompletionDate;
    switch (item.attributes.subdimension) {
      case "Business Role Taxonomy":
        dimensionLatestCompletionDate =
          state.program?.data.attributes.latestCompletionDateBUS;
        break;
      case "Financing":
        dimensionLatestCompletionDate =
          state.program?.data.attributes.latestCompletionDateFIN;
        break;
      case "IAM Controls":
        dimensionLatestCompletionDate =
          state.program?.data.attributes.latestCompletionDateCON;
        break;
      case "IAM Processes":
        dimensionLatestCompletionDate =
          state.program?.data.attributes.latestCompletionDatePRO;
        break;
      case "IAM Tooling":
        dimensionLatestCompletionDate =
          state.program?.data.attributes.latestCompletionDateTOO;
        break;
      case "Organization & Accountabilities":
        dimensionLatestCompletionDate =
          state.program?.data.attributes.latestCompletionDateORG;
        break;
      case "Policies":
        dimensionLatestCompletionDate =
          state.program?.data.attributes.latestCompletionDatePOL;
        break;
      case "Requirements for IT Assets":
        dimensionLatestCompletionDate =
          state.program?.data.attributes.latestCompletionDateREQ;
        break;
    }
    return dimensionLatestCompletionDate;
  }

  private buildDimensionReportingView({ viewname }: { viewname: Viewname }) {
    if (!this.reportView || !this.app) {
      return;
    }

    this.buildDimensionTitleCard(viewname);
    this.buildMaturityCard(viewname);
    this.buildActivitiesCard(viewname);
    this.buildActivitiesInSubdimensionsCard(viewname);
    this.buildStatusCard(viewname);
    this.buildCommentsCard(viewname);
    this.buildKeyInformationCard(viewname);
    this.buildStatusOfActivitiesTable(viewname);
    this.buildRoute();
  }

  private buildDimensionTitleCard(viewname: Viewname) {
    if (!this.reportView) {
      return;
    }

    const titleComponent = this.reportView.addComponent({
      component: ReportTitleCard,
      layoutProps: { viewname, xs: 12 },
    });

    titleComponent.mapState((state) => {
      const { endReportingDate, startReportingDate, reportingFrequency } =
        state.program?.data.attributes ?? {};
      const {
        name,
        team = [],

        modelDimensionUpdatedAt = "",
        modelDimensionUpdatedBy,
        govDimensionUpdatedAt = "",
        govDimensionUpdatedBy,
        techDimensionUpdatedAt = "",
        techDimensionUpdatedBy,
      } = state.currentOEProject?.data.attributes ?? {};
      let dimensionUpdatedAt: string | undefined;
      let dimensionUpdatedBy: string | undefined;
      switch (viewname) {
        case "iam-gov":
          dimensionUpdatedAt = govDimensionUpdatedAt;
          dimensionUpdatedBy = govDimensionUpdatedBy;
          break;
        case "iam-model":
          dimensionUpdatedAt = modelDimensionUpdatedAt;
          dimensionUpdatedBy = modelDimensionUpdatedBy;
          break;
        case "iam-tech":
          dimensionUpdatedAt = techDimensionUpdatedAt;
          dimensionUpdatedBy = techDimensionUpdatedBy;
          break;
      }
      const formattedReportingDate = formatDate(endReportingDate ?? "");
      const title = [
        ["Status Report", this.getDimensionForViewname(viewname)].join(" "),
        [name, formattedReportingDate && `(as of ${formattedReportingDate})`]
          .filter(Boolean)
          .join(" "),
      ].join(" - ");

      return {
        title,
        members: team.map(({ person, role }) => ({
          name: person?.data?.attributes.name ?? "",
          position: role?.data?.attributes.name ?? "",
        })),
        showEditButton: canUserAccessEditPage(
          state.userProfile,
          state.currentOEProject?.data
        ),
        reportingDateIndicator: {
          ...getReportingStatus({
            lastUpdatedDateInStringFormat: dimensionUpdatedAt,
            endReportingDateInStringFormat: endReportingDate,
            startReportingDateInStringFormat: startReportingDate,
            reportingFrequency,
            conditionUponWhichToReturnGreenByDefault: areAllActivitiesCompleted(
              state.currentOEProject?.data.attributes.activities.data.filter(
                (a) =>
                  a.attributes.dimension ===
                  this.getDimensionForViewname(viewname)
              ) ?? []
            ),
          }),
          onHoverText: dimensionUpdatedAt
            ? `as of ${formatDateTime(dimensionUpdatedAt)} ${
                dimensionUpdatedBy ? `by ${dimensionUpdatedBy}` : ""
              }`
            : "",
        },
      };
    });
    titleComponent.on("edit-button:click", () => {
      this.app?.navigate("edit");
    });
  }

  private buildMaturityCard(viewname: Viewname) {
    if (!this.reportView) {
      return;
    }

    const dimension = this.getDimensionForViewname(viewname);

    const maturity = this.reportView.addComponent({
      component: CategoriesWithValuesInBadgesCard,
      layoutProps: { viewname, xs: 3 },
    });

    maturity.mapState((state) => {
      const maturityInfo =
        state.currentOEProject?.data.attributes
          .maturityLevelPerDimensionAndSubdimension?.[dimension];
      const {
        colorScheme: lowestMaturityColorScheme,
        maturity: lowestMaturityFormatted,
      } = getChipAndTextColorForMaturityLevel(
        getMaturityLevelNumberForMaturityLevel(
          maturityInfo?.lowestMaturity ?? "very low 1"
        )
      );
      const {
        colorScheme: averageMaturityColorScheme,
        maturity: averageMaturityFormatted,
      } = getChipAndTextColorForMaturityLevel(
        getMaturityLevelNumberForMaturityLevel(
          maturityInfo?.averageMaturity ?? "very low 1"
        )
      );
      return {
        cardTitle: "Maturity Level",
        cardIcon: "maturity.svg",
        chipBackgroundColor: [
          lowestMaturityColorScheme.chipColor,
          averageMaturityColorScheme.chipColor,
        ],
        chiptextColor: [
          lowestMaturityColorScheme.textColor,
          averageMaturityColorScheme.textColor,
        ],
        categories: [
          {
            category: "Lowest",
            value: lowestMaturityFormatted,
          },
          {
            category: "Average",
            value: averageMaturityFormatted,
          },
        ],
      };
    });
  }

  private buildActivitiesCard(viewname: Viewname) {
    if (!this.reportView) {
      return;
    }

    const dimensionForView = this.getDimensionForViewname(viewname);

    const activities = this.reportView.addComponent({
      component: ArtefactsProgressCard,
      layoutProps: {
        viewname,
        xs: 3,
      },
    });

    activities.mapState((state) => {
      const activityProgress =
        state.currentOEProject?.data.attributes.activityProgressPerDimension?.[
          dimensionForView
        ] ?? 100;

      return {
        cardTitle: "Activities",
        cardIcon: "activities.svg",
        value: activityProgress,
        displayValue: `${Math.round(activityProgress)} %`,
        label: "Progress",
        color: IAM_COLORS.babyBlue,
      };
    });
  }
  private buildStatusCard(viewname: Viewname) {
    if (!this.reportView || !this.app) {
      return;
    }
    const dimensionStatusCard = this.reportView.addComponent({
      component: StatusCard,
      layoutProps: {
        viewname,
        xs: 3,
      },
    });
    const dimensionForView = this.getDimensionForViewname(viewname);

    dimensionStatusCard.mapState((state) => {
      const { activityPerStatusPerOEAndDimension } =
        state.currentOEProject?.data?.attributes ?? {};

      return {
        statuses: [
          {
            status: STATUS_TYPE.WHITE,
            name: "Not started ",
            count:
              activityPerStatusPerOEAndDimension?.[dimensionForView][
                "not started"
              ] ?? 0,
          },
          {
            status: STATUS_TYPE.YELLOW,
            name: "At risk ",
            count:
              activityPerStatusPerOEAndDimension?.[dimensionForView][
                "at risk"
              ] ?? 0,
          },
          {
            status: STATUS_TYPE.GREEN,
            name: "On track ",
            count:
              activityPerStatusPerOEAndDimension?.[dimensionForView][
                "on track"
              ] ?? 0,
          },
          {
            status: STATUS_TYPE.COMPLETED,
            name: "Completed ",
            count:
              state.currentOEProject?.data?.attributes
                ?.activityPerStatusPerOEAndDimension?.[dimensionForView]
                .completed ?? 0,
          },
        ],
        subStatuses: [],
        title: "Status",
        isRow: false,
        cardProps: {},
      };
    });
  }

  private buildActivitiesInSubdimensionsCard(viewname: Viewname) {
    if (!this.reportView) {
      return;
    }

    const activitiesInSubDimensions = this.reportView.addComponent({
      component: CategoriesWithValuesInBadgesCard,
      layoutProps: {
        viewname,
        xs: 3,
      },
    });

    const dimensionForView = this.getDimensionForViewname(viewname);

    activitiesInSubDimensions.mapState((state) => {
      const completedActivitiesInDimension =
        state.currentOEProject?.data.attributes
          .completedActivitiesPerDimensionAndSubDimension?.[dimensionForView];

      return {
        cardTitle: `Completed Activities (${
          completedActivitiesInDimension?.completed ?? 0
        }/${completedActivitiesInDimension?.overall ?? 0})`,
        categories:
          completedActivitiesInDimension?.perSubdimension.map((entry) => ({
            category: entry.subdimension,
            value: `${entry.completed} / ${entry.overall}`,
          })) ?? [],
        cardIcon: "checklist.svg",
        chipBackgroundColor:
          completedActivitiesInDimension?.perSubdimension.map(
            () => IAM_COLORS.babyBlue
          ) ?? [],
        chipForegroundColor: "#ffffff",
        color: "#94CADC",
      };
    });
  }

  private buildCommentsCard(viewname: Viewname) {
    if (!this.reportView) {
      return;
    }

    const comments = this.reportView.addComponent({
      component: LargeTextCard,
      layoutProps: {
        viewname,
        xs: 6,
      },
    });

    comments.mapState((state) => {
      const { commentIAMModel, commentIAMGovernance, commentIAMTechnology } =
        state.currentOEProject?.data.attributes ?? {};
      let comment = "";
      switch (viewname) {
        case "iam-model": {
          comment = commentIAMModel ?? "";
          break;
        }
        case "iam-gov": {
          comment = commentIAMGovernance ?? "";
          break;
        }
        case "iam-tech": {
          comment = commentIAMTechnology ?? "";
          break;
        }
      }
      return {
        cardTitle: "Comments on Project Status",
        textContent: comment,
      };
    });
  }

  private buildKeyInformationCard(viewname: Viewname) {
    if (!this.reportView) {
      return;
    }

    const keyInformation = this.reportView.addComponent({
      component: KeyInformationCard,
      layoutProps: {
        viewname,
        xs: 6,
      },
    });

    const dimensionForView = this.getDimensionForViewname(viewname);

    switch (dimensionForView) {
      case "IAM Model": {
        keyInformation.mapState((state) => ({
          title: "Key Information",
          entries: [
            {
              label: "Business Role Management Tool",
              value:
                (state.currentOEProject?.data.attributes
                  .businessRoleManagementTool &&
                  "yes") ||
                "no",
            },
          ],
        }));
        break;
      }
      case "IAM Governance": {
        keyInformation.mapState((state) => ({
          title: "Key Information",
          entries: [
            {
              label: "OE IAM Governance (#FTE)",
              value:
                state.currentOEProject?.data.attributes.kpiOEIAMGovernance !=
                null
                  ? state.currentOEProject?.data.attributes.kpiOEIAMGovernance
                  : "n/a",
            },
            {
              label: "OE IAM Business Operations (#FTE)",
              value:
                state.currentOEProject?.data.attributes
                  .kpiOEIAMBusinessOperations != null
                  ? state.currentOEProject?.data.attributes
                      .kpiOEIAMBusinessOperations
                  : "n/a",
            },
            {
              label: "IAM Change Budget 2024 (€)",
              value:
                state.currentOEProject?.data.attributes.kpiIAMChangeBudget !=
                null
                  ? state.currentOEProject?.data.attributes.kpiIAMChangeBudget
                  : "n/a",
            },
          ],
        }));
        break;
      }
      case "IAM Technology": {
        keyInformation.mapState((state) => {
          let localIamTool: string | undefined =
            state.currentOEProject?.data.attributes.localIAMTool;

          if (
            localIamTool === "Other" &&
            state.currentOEProject?.data.attributes.otherLocalIAMTool
          ) {
            localIamTool =
              state.currentOEProject?.data.attributes.otherLocalIAMTool;
          }

          if (!localIamTool) {
            localIamTool = "n/a";
          }

          return {
            title: "Key Information",
            entries: [
              {
                label: "Local IAM Tool (name)",
                value: localIamTool,
              },
              {
                label: "# of Local IT Assets",
                value:
                  state.currentOEProject?.data.attributes
                    .kpiNumberOfLocalITAssets != null
                    ? state.currentOEProject?.data.attributes
                        .kpiNumberOfLocalITAssets
                    : "n/a",
              },
              {
                label: "# of Local IT Assets Onboarded to IAM Tooling",
                value:
                  state.currentOEProject?.data.attributes
                    .kpiLocalITAssetsOnboardedToIAMTooling != null
                    ? state.currentOEProject?.data.attributes
                        .kpiLocalITAssetsOnboardedToIAMTooling
                    : "n/a",
              },
              {
                label:
                  "% of Local IT Assets that have an Authorization Concept which is regularly reviewed and updated",
                value:
                  state.currentOEProject?.data.attributes
                    .kpiNumberOfAuthorizationConceptsForITAssets != null
                    ? state.currentOEProject?.data.attributes
                        .kpiNumberOfAuthorizationConceptsForITAssets
                    : "n/a",
              },
            ],
          };
        });
        break;
      }
    }
  }

  private getDimensionForViewname(viewname: Viewname): Dimension {
    switch (viewname) {
      case "iam-model":
        return "IAM Model";
      case "iam-gov":
        return "IAM Governance";
      case "iam-tech":
        return "IAM Technology";
      default:
        return "IAM Governance";
    }
  }

  private buildRoute() {
    if (!this.app || !this.reportView) {
      return;
    }

    this.route = this.app.createRoute<"reportId" | "viewname">({
      path: "/reports/:reportId/:viewname",
      view: this.reportView,
    });

    this.route.on("load", async ({ payload }) => {
      const params = payload?.parameters;
      if (!params?.reportId) {
        return;
      }

      const currentOEProject = await fetchOEProject(params.reportId);
      const program = await fetchProgram();
      const attachments = await fetchAllAttachmentsForProject(params.reportId);
      const trends = await fetchTrend(params.reportId);
      const overallTrends = generateTrendEntriesFromCalculatedTrends({
        scale: "monthly",
        year: new Date().getFullYear(),
        calculatedActuals: trends.actuals ?? [],
        calculatedPlanned: trends.planned ?? [],
        calculatedRecommended: trends.recommendedMaturitiesOverall ?? [],
      });
      const modelTrends = generateTrendEntriesFromCalculatedTrends({
        scale: "monthly",
        year: new Date().getFullYear(),
        calculatedActuals: trends.modelActuals ?? [],
        calculatedPlanned: trends.modelPlanned ?? [],
        calculatedRecommended: trends.recommendedMaturitiesOverall ?? [],
      });
      const govTrends = generateTrendEntriesFromCalculatedTrends({
        scale: "monthly",
        year: new Date().getFullYear(),
        calculatedActuals: trends.govActuals ?? [],
        calculatedPlanned: trends.govPlanned ?? [],
        calculatedRecommended: trends.recommendedMaturitiesOverall ?? [],
      });
      const techTrends = generateTrendEntriesFromCalculatedTrends({
        scale: "monthly",
        year: new Date().getFullYear(),
        calculatedActuals: trends.techActuals ?? [],
        calculatedPlanned: trends.techPlanned ?? [],
        calculatedRecommended: trends.recommendedMaturitiesOverall ?? [],
      });
      if (!currentOEProject || !program || !this.app) {
        return;
      }

      const state = this.app.getAppState();

      this.app.setAppState({
        ...state,
        currentOEProject,
        program,
        attachments,
        overallTrends: {
          overall: {
            actuals: trends.actuals,
            planned: trends.planned,
            recommended: trends.recommendedMaturitiesOverall,
          },
          model: {
            actuals: trends.modelActuals,
            planned: trends.modelPlanned,
            recommended: trends.recommendedMaturitiesOverall,
          },
          gov: {
            actuals: trends.govActuals,
            planned: trends.govPlanned,
            recommended: trends.recommendedMaturitiesOverall,
          },
          tech: {
            actuals: trends.techActuals,
            planned: trends.techPlanned,
            recommended: trends.recommendedMaturitiesOverall,
          },
        },
        currentTrends: {
          overall: overallTrends,
          model: modelTrends,
          gov: govTrends,
          tech: techTrends,
        },
        trendFilter: {
          overall: {
            trendScale: "monthly",
            trendYear: new Date().getFullYear(),
          },
          model: { trendScale: "monthly", trendYear: new Date().getFullYear() },
          gov: { trendScale: "monthly", trendYear: new Date().getFullYear() },
          tech: { trendScale: "monthly", trendYear: new Date().getFullYear() },
        },
      });
    });

    const editView = this.app.createView({
      name: "Edit",
      layout: new RowLayout(),
    });
    const editRoute = this.route.createChildRoute({
      path: "edit",
      view: editView,
      isOverlayChild: true,
      isVisible: true,
    });

    editRoute.on("load", (payload) => {
      const viewName = payload.payload?.parameters?.viewname;

      switch (viewName) {
        case "iam-model":
          this.app?.setAppState({
            ...this.app?.getAppState(),
            editDialogData: {
              dimension: "iam-model",
              activitiesGroupedBySubdimension:
                transformActivitiesToActivitiesGroupedBySubdimesion(
                  this.getDimensionForViewname("iam-model"),
                  this.app?.getAppState().currentOEProject
                ),
              commentOverallStatus:
                this.app?.getAppState().currentOEProject?.data.attributes
                  .commentIAMModel,
              businessRoleManagementTool:
                this.app?.getAppState().currentOEProject?.data.attributes
                  .businessRoleManagementTool === true
                  ? "yes"
                  : "no",
            },
          });
          break;
        case "iam-gov":
          this.app?.setAppState({
            ...this.app?.getAppState(),
            editDialogData: {
              dimension: "iam-gov",
              activitiesGroupedBySubdimension:
                transformActivitiesToActivitiesGroupedBySubdimesion(
                  this.getDimensionForViewname("iam-gov"),
                  this.app?.getAppState().currentOEProject
                ),
              commentOverallStatus:
                this.app?.getAppState().currentOEProject?.data.attributes
                  .commentIAMGovernance,
              kpiOEIAMGovernance:
                this.app?.getAppState().currentOEProject?.data.attributes
                  .kpiOEIAMGovernance,
              kpiOEIAMBusinessOperations:
                this.app?.getAppState().currentOEProject?.data.attributes
                  .kpiOEIAMBusinessOperations,
              kpiIAMChangeBudget:
                this.app?.getAppState().currentOEProject?.data.attributes
                  .kpiIAMChangeBudget,
            },
          });
          break;
        case "iam-tech":
          this.app?.setAppState({
            ...this.app?.getAppState(),
            editDialogData: {
              dimension: "iam-tech",
              activitiesGroupedBySubdimension:
                transformActivitiesToActivitiesGroupedBySubdimesion(
                  this.getDimensionForViewname("iam-tech"),
                  this.app?.getAppState().currentOEProject
                ),
              commentOverallStatus:
                this.app?.getAppState().currentOEProject?.data.attributes
                  .commentIAMTechnology,
              localIAMTool:
                this.app?.getAppState().currentOEProject?.data.attributes
                  .localIAMTool,
              otherLocalIAMTool:
                this.app?.getAppState().currentOEProject?.data.attributes
                  .otherLocalIAMTool,
              kpiNumberOfLocalITAssets:
                this.app?.getAppState().currentOEProject?.data.attributes
                  .kpiNumberOfLocalITAssets,
              kpiLocalITAssetsOnboardedToIAMTooling:
                this.app?.getAppState().currentOEProject?.data.attributes
                  .kpiLocalITAssetsOnboardedToIAMTooling,
              kpiNumberOfAuthorizationConceptsForITAssets:
                this.app?.getAppState().currentOEProject?.data.attributes
                  .kpiNumberOfAuthorizationConceptsForITAssets,
            },
          });
          break;
      }
    });

    const editDialog = editView.addComponent({
      component: EditDialog,
      layoutProps: { row: 2 },
    });

    editDialog.mapState((state) => ({
      title: `Update - ${this.getDimensionForViewname(
        state.editDialogData?.dimension ?? "iam-gov"
      )}`,
      data: state.editDialogData ?? {
        dimension: "iam-gov",
      },
      reportingDate: formatDate(
        this.app?.getAppState().program?.data.attributes.endReportingDate ?? ""
      ),
      isLoading: false,
    }));

    editDialog.on("edit-dialog:submit", async ({ payload }) => {
      if (!payload || !this.app || !this.reportView) {
        return;
      }

      await updateOeProjectAndRelatedActivities(
        payload,
        this.app?.getAppState().currentOEProject?.data.id
      );

      this.app?.navigate("../");
    });

    editDialog.on("edit-dialog:close", () => {
      this.app?.navigate("../");
    });
  }
}

function customSort(
  arr: StrapiCollectionEntry<ActivityAttributes>[]
): StrapiCollectionEntry<ActivityAttributes>[] {
  const order: { [key: string]: number } = { b: 1, p: 2, c: 3 };
  return arr.sort((a, b) => {
    const aFirstChar = a.attributes.activityId?.charAt(0).toLowerCase() ?? "";
    const bFirstChar = b.attributes.activityId?.charAt(0).toLowerCase() ?? "";
    return (order[aFirstChar] || 4) - (order[bFirstChar] || 4);
  });
}
