import type { App, Plugin, Route, View } from "@pimo/pimo-app-builder";
import {
  CombinedActivityStatusCard,
  DonutChartCard,
  GridLayout,
  GridLayoutProps,
  InformationCard,
  KeyInformationCard,
  LineGaugeCard,
  SectionHeader,
  TeamCard,
} from "@pimo/pimo-components";
import {
  ActivityStatus,
  DIMENSIONS,
  MaturityLevelColorScheme,
  type Dashboard,
  type OEProjectAPIAttributes,
  type ProgramAPIAttributes,
  type SingleStrapiResponse,
  type StrapiCollectionEntry,
} from "iam-types";
import {
  FORMATTED_MATURITY_LEVELS,
  IAM_COLORS,
  PROJECT_END_DATE,
  PROJECT_START_DATE,
  capitalize,
  determineArcherColorForMaturity,
  formatDate,
  generateTrendEntriesFromCalculatedTrends,
  getChipAndTextColorForMaturityLevel,
  getPercentageFromMaturityNumber,
  getRoundedAverageMaturity,
  roundToTheNearestTenth,
} from "iam-utils";

import { ReportTitleCard } from "../../components/report-title-card/report-title-card";
import { IAMAppState } from "../app";
import { APP_ROUTES } from "../constants";
import {
  LineChartCard,
  type LineChartCardEventMap,
} from "../../components/line-chart-card/line-chart-card";
import { DimensionsBestWorstCard } from "../components/dimensions-best-worst-card";

export type DashboardPluginPartialAppState = {
  dashboard?: Dashboard;
  oeProjects?: StrapiCollectionEntry<OEProjectAPIAttributes>[];
  program?: SingleStrapiResponse<StrapiCollectionEntry<ProgramAPIAttributes>>;
};

export class DashboardPlugin implements Plugin<IAMAppState, IAMAppState> {
  route?: Route;
  private app?: App<IAMAppState>;
  onRegister(app: App<IAMAppState>): void {
    this.app = app;
    const dashboardView = app.createView({
      name: "dashboard",
      layout: new GridLayout(),
    });

    this.buildDashboardView(dashboardView);

    this.route = app.createRoute({
      path: APP_ROUTES.dashboard,
      view: dashboardView,
    });
  }

  buildDashboardView(view: View<IAMAppState, GridLayoutProps>) {
    const title = view.addComponent({
      component: ReportTitleCard,
      layoutProps: {
        xs: 12,
      },
    });

    title.mapState((state) => {
      const { name = "", endReportingDate = "" } =
        state.program?.data.attributes ?? {};
      const completionDate = state?.dashboard?.completionDate;
      const date = formatDate(endReportingDate);

      return {
        title: [
          "Group Dashboard",
          [name, date && `(as of ${date})`].filter(Boolean).join(" "),
        ].join(" - "),
        endDate: completionDate ? formatDate(completionDate) : "n/a",
      };
    });

    const overallMaturityTitle = view.addComponent({
      component: SectionHeader,
      layoutProps: {
        xs: 12,
      },
    });

    overallMaturityTitle.mapState((state) => {
      const overallAverageMaturityLevel =
        state.dashboard?.overallAverageMaturity?.averageMaturityNumber ?? 0;

      const { colorScheme, maturity } = getChipAndTextColorForMaturityLevel(
        overallAverageMaturityLevel
      );
      const { colorScheme: colorSchemeArcherColorCoding } =
        determineArcherColorForMaturity(overallAverageMaturityLevel);
      const maturityWithoutNumber = maturity.replace(/ \(\d\)$/, "");

      return {
        title: `Overall Maturity (reported as Information Security Health Indicator):`,
        badgeTextContent: `${maturityWithoutNumber} (${overallAverageMaturityLevel.toFixed(
          1
        )})`,
        circleColor: colorSchemeArcherColorCoding.chipColor,
        sx: {
          "@media print": {
            pageBreakBefore: "avoid",
          },
        },
        ...colorScheme,
      };
    });

    for (const dimension of DIMENSIONS) {
      const gaugeCard = view.addComponent({
        component: LineGaugeCard,
        layoutProps: {
          xs: 4,
        },
      });

      gaugeCard.mapState((state) => {
        const completionDate =
          state.dashboard?.completionDatePerDimension?.[dimension]
            ?.completionDate;

        return {
          title: `Maturity - ${dimension}`,
          showStartDate: false,
          startDate: "",
          endDate: completionDate ? formatDate(completionDate) : "n/a",
          entries: [
            {
              label: "Average",
              isTitle: true,
              percentage: getPercentageFromMaturityNumber(
                state.dashboard?.maturityLevelPerDimensionAndSubdimension[
                  dimension
                ].averageMaturityNumber ?? 1
              ),
              value:
                state.dashboard?.maturityLevelPerDimensionAndSubdimension[
                  dimension
                ].averageMaturityNumber ?? 1,
            },
            ...(state.dashboard?.maturityLevelPerDimensionAndSubdimension[
              dimension
            ].perSubdimension.map((entry) => ({
              label: entry.subdimension,
              percentage: getPercentageFromMaturityNumber(entry.maturityNumber),
              value: entry.maturityNumber,
            })) ?? []),
          ],
          scale: [1, 2, 3, 4, 5],
        };
      });
    }

    const maturityOfOEsDonutCard = view.addComponent({
      component: DonutChartCard,
      layoutProps: {
        xs: 4,
      },
    });

    maturityOfOEsDonutCard.mapState((state) => {
      const numberOfOEsWithAverageMaturityOne = state?.oeProjects?.filter(
        (oe) =>
          oe.attributes.overallAverageMaturityLevel &&
          getRoundedAverageMaturity(
            oe.attributes.overallAverageMaturityLevel
          ) === 1
      ).length;

      const numberOfOEsWithAverageMaturityTwo = state?.oeProjects?.filter(
        (oe) =>
          oe.attributes.overallAverageMaturityLevel &&
          getRoundedAverageMaturity(
            oe.attributes.overallAverageMaturityLevel
          ) === 2
      ).length;

      const numberOfOEsWithAverageMaturityThree = state?.oeProjects?.filter(
        (oe) =>
          oe.attributes.overallAverageMaturityLevel &&
          getRoundedAverageMaturity(
            oe.attributes.overallAverageMaturityLevel
          ) === 3
      ).length;

      const numberOfOEsWithAverageMaturityFour = state?.oeProjects?.filter(
        (oe) =>
          oe.attributes.overallAverageMaturityLevel &&
          getRoundedAverageMaturity(
            oe.attributes.overallAverageMaturityLevel
          ) === 4
      ).length;

      const numberOfOEsWithAverageMaturityFive = state?.oeProjects?.filter(
        (oe) =>
          oe.attributes.overallAverageMaturityLevel &&
          getRoundedAverageMaturity(
            oe.attributes.overallAverageMaturityLevel
          ) === 5
      ).length;

      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: "# of OEs per Maturity Level",
        labelBadgesColors: Object.values(
          IAM_COLORS.maturityLevels
        ) as MaturityLevelColorScheme[],
        series: [
          numberOfOEsWithAverageMaturityOne,
          numberOfOEsWithAverageMaturityTwo,
          numberOfOEsWithAverageMaturityThree,
          numberOfOEsWithAverageMaturityFour,
          numberOfOEsWithAverageMaturityFive,
        ],
        backgroundColor: Object.values(IAM_COLORS.maturityLevels).map(
          (maturityLevel) => maturityLevel.chipColor
        ),
        labels: FORMATTED_MATURITY_LEVELS.map(
          (maturity) => maturity.formattedMaturity
        ),
        legendPosition: "left",
        toolTipEnabled: true,
        toolTipLabels: FORMATTED_MATURITY_LEVELS.map(
          (maturity) => maturity.formattedMaturity
        ),
      };
    });

    const dimsAndSubdims = view.addComponent({
      component: DimensionsBestWorstCard,
      layoutProps: { xs: 8 },
    });
    dimsAndSubdims.mapState((state) => {
      const bestDim = state.dashboard?.bestDimension;
      const worstDim = state.dashboard?.worstDimension;
      const bestSubDim = state.dashboard?.bestSubdimension;
      const worstSubDim = state.dashboard?.worstSubdimension;
      return {
        bestDim: {
          cardTitle: "Best Dimension:",
          subtitle: bestDim?.dimension ?? "IAM Model",
          cardIcon: "maturity.svg",
          chipContent: {
            ...bestDim,
          },
        },
        worstDim: {
          cardTitle: "Worst Dimension:",
          subtitle: worstDim?.dimension ?? "IAM Model",
          cardIcon: "maturity.svg",
          chipContent: {
            ...worstDim,
          },
        },
        bestSubDim: {
          cardTitle: "Best Sub Dimension:",
          subtitle: bestSubDim?.dimension ?? "",
          cardIcon: "maturity.svg",
          chipContent: {
            ...bestSubDim,
          },
        },
        worstSubDim: {
          cardTitle: "Worst Sub Dimension:",
          subtitle: worstSubDim?.dimension ?? "",
          cardIcon: "maturity.svg",
          chipContent: {
            ...worstSubDim,
          },
        },
      };
    });

    /* 
    const bestDimensionCard = view.addComponent({
      component: SmallCardWithChip,
      layoutProps: { xs: 3 },
    });

    bestDimensionCard.mapState((state) => {
      const bestDim = state.dashboard?.bestDimension;
      return {
        cardTitle: "Best Dimension:",
        subtitle: bestDim?.dimension ?? "IAM Model",
        cardIcon: "maturity.svg",
        chipContent: {
          ...bestDim,
        },
      };
    });

    const worstDimensionCard = view.addComponent({
      component: SmallCardWithChip,
      layoutProps: { xs: 3 },
    });

    worstDimensionCard.mapState((state) => {
      const worstDim = state.dashboard?.worstDimension;
      return {
        cardTitle: "Worst Dimension:",
        subtitle: worstDim?.dimension ?? "IAM Model",
        cardIcon: "maturity.svg",
        chipContent: {
          ...worstDim,
        },
      };
    });
    const bestSubDimensionCard = view.addComponent({
      component: SmallCardWithChip,
      layoutProps: { xs: 3 },
    });

    bestSubDimensionCard.mapState((state) => {
      const bestSubDim = state.dashboard?.bestSubdimension;
      return {
        cardTitle: "Best Sub Dimension:",
        subtitle: bestSubDim?.dimension ?? "",
        cardIcon: "maturity.svg",
        chipContent: {
          ...bestSubDim,
        },
      };
    });
    const worstSubDimensionCard = view.addComponent({
      component: SmallCardWithChip,
      layoutProps: { xs: 3 },
    });

    worstSubDimensionCard.mapState((state) => {
      const worstSubDim = state.dashboard?.worstSubdimension;
      return {
        cardTitle: "Worst Sub Dimension:",
        subtitle: worstSubDim?.dimension ?? "",
        cardIcon: "maturity.svg",
        chipContent: {
          ...worstSubDim,
        },
      };
    });
 */
    const oeProgressLineChart = view.addComponent({
      component: LineChartCard,
      layoutProps: { xs: 12 },
    });

    oeProgressLineChart.mapState((state) => {
      const { actuals, planned, recommended } =
        generateTrendEntriesFromCalculatedTrends({
          scale: "monthly",
          year: new Date().getFullYear(),
          calculatedActuals: state.dashboard?.trends.overallActuals ?? [],
          calculatedPlanned: state.dashboard?.trends.overallActuals ?? [],
          calculatedRecommended:
            state.dashboard?.trends.recommendedMaturitiesOverall ?? [],
        });
      return {
        canToggleVisibilty: true,
        title: "Trend Group Level",
        startDate: PROJECT_START_DATE,
        endDate: PROJECT_END_DATE,
        currentScale: state.trendFilter?.overall.trendScale,
        currentYear:
          state.trendFilter?.overall.trendYear ?? new Date().getFullYear(),
        series: [
          {
            id: "actual",
            label: "Actual",
            data:
              state.currentTrends?.overall.actuals.map((val) =>
                val ? roundToTheNearestTenth(val) : val
              ) ??
              actuals.map((val) => (val ? roundToTheNearestTenth(val) : val)),
            curve: "linear",
          },
          {
            id: "planned",
            label: "Planned",
            data:
              state.currentTrends?.overall.planned.map((val) =>
                val ? roundToTheNearestTenth(val) : val
              ) ??
              planned.map((val) => (val ? roundToTheNearestTenth(val) : val)),
            curve: "linear",
          },
          {
            id: "recommended",
            label: "Minimum Target",
            data:
              state.currentTrends?.overall.recommended?.map((val) =>
                val ? roundToTheNearestTenth(val) : val
              ) ??
              recommended.map((val) =>
                val ? roundToTheNearestTenth(val) : val
              ),
            curve: "linear",
            connectNulls: true,
            /** index equal to 10 because in case of monthly only November (the 10 month) should have a dot */
            showMark: ({ index }) =>
              state.trendFilter?.overall.trendScale === "monthly"
                ? index === 10
                : true,
          },
        ],
      };
    });

    oeProgressLineChart.on("change-time", (event) => {
      const payload = event.payload as LineChartCardEventMap["change-time"];
      const state = this.app?.getAppState();

      if (!payload || !state || !payload.scale || !payload.year) {
        return;
      }

      this.app?.setAppState({
        ...state,
        trendFilter: {
          ...(state.trendFilter ?? {
            model: {
              trendScale: "monthly",
              trendYear: new Date().getFullYear(),
            },
            tech: {
              trendScale: "monthly",
              trendYear: new Date().getFullYear(),
            },
            gov: { trendScale: "monthly", trendYear: new Date().getFullYear() },
          }),

          overall: { trendScale: payload.scale, trendYear: payload.year },
        },

        currentTrends: {
          ...(state.currentTrends ?? {
            model: { actuals: [], planned: [] },
            gov: { actuals: [], planned: [] },
            tech: { actuals: [], planned: [] },
          }),
          overall: generateTrendEntriesFromCalculatedTrends({
            scale: payload.scale,
            year: payload.year,
            calculatedActuals: state.dashboard?.trends.overallActuals ?? [],
            calculatedPlanned: state.dashboard?.trends.overallPlanned ?? [],
            calculatedRecommended:
              state.dashboard?.trends.recommendedMaturitiesOverall ?? [],
          }),
        },
      });
    });

    const modelLineChart = view.addComponent({
      component: LineChartCard,
      layoutProps: { xs: 4 },
    });

    modelLineChart.mapState((state) => {
      const { actuals, planned, recommended } =
        generateTrendEntriesFromCalculatedTrends({
          scale: "monthly",
          year: new Date().getFullYear(),
          calculatedActuals: state.dashboard?.trends.overallActuals ?? [],
          calculatedPlanned: state.dashboard?.trends.overallPlanned ?? [],
          calculatedRecommended:
            state.dashboard?.trends.recommendedMaturitiesOverall ?? [],
        });
      return {
        title: "IAM Model Trends",
        currentScale: state.trendFilter?.model.trendScale,
        currentYear:
          state.trendFilter?.model.trendYear ?? new Date().getFullYear(),
        startDate: PROJECT_START_DATE,
        endDate: PROJECT_END_DATE,
        series: [
          {
            id: "actual",
            label: "Actual",
            data:
              state.currentTrends?.model.actuals.map((val) =>
                val ? roundToTheNearestTenth(val) : val
              ) ??
              actuals.map((val) => (val ? roundToTheNearestTenth(val) : val)),
            curve: "linear",
          },
          {
            id: "planned",
            label: "Planned",
            data:
              state.currentTrends?.model.planned.map((val) =>
                val ? roundToTheNearestTenth(val) : val
              ) ??
              planned.map((val) => (val ? roundToTheNearestTenth(val) : val)),
            curve: "linear",
          },
          {
            id: "recommended",
            label: "Minimum Target",
            data:
              state.currentTrends?.model.recommended?.map((val) =>
                val ? roundToTheNearestTenth(val) : val
              ) ??
              recommended.map((val) =>
                val ? roundToTheNearestTenth(val) : val
              ),
            curve: "linear",
            connectNulls: true,
            /** index equal to 10 because in case of monthly only November (the 10 month) should have a dot */
            showMark: ({ index }) =>
              state.trendFilter?.model.trendScale === "monthly"
                ? index === 10
                : true,
          },
        ],
      };
    });

    modelLineChart.on("change-time", (event) => {
      const payload = event.payload as LineChartCardEventMap["change-time"];
      const state = this.app?.getAppState();

      if (!payload || !state || !payload.scale || !payload.year) {
        return;
      }

      this.app?.setAppState({
        ...state,
        trendFilter: {
          ...(state.trendFilter ?? {
            tech: {
              trendScale: "monthly",
              trendYear: new Date().getFullYear(),
            },
            overall: {
              trendScale: "monthly",
              trendYear: new Date().getFullYear(),
            },
            gov: { trendScale: "monthly", trendYear: new Date().getFullYear() },
          }),

          model: { trendScale: payload.scale, trendYear: payload.year },
        },
        currentTrends: {
          ...(state.currentTrends ?? {
            overall: { actuals: [], planned: [] },
            gov: { actuals: [], planned: [] },
            tech: { actuals: [], planned: [] },
          }),
          model: generateTrendEntriesFromCalculatedTrends({
            scale: payload.scale,
            year: payload.year,
            calculatedActuals: state.dashboard?.trends.modelActuals ?? [],
            calculatedPlanned: state.dashboard?.trends.modelPlanned ?? [],
            calculatedRecommended:
              state.dashboard?.trends.recommendedMaturitiesOverall ?? [],
          }),
        },
      });
    });

    const govLineChart = view.addComponent({
      component: LineChartCard,
      layoutProps: { xs: 4 },
    });

    govLineChart.mapState((state) => {
      const { actuals, planned, recommended } =
        generateTrendEntriesFromCalculatedTrends({
          scale: "monthly",
          year: new Date().getFullYear(),
          calculatedActuals: state.dashboard?.trends.govActuals ?? [],
          calculatedPlanned: state.dashboard?.trends.govPlanned ?? [],
          calculatedRecommended:
            state.dashboard?.trends.recommendedMaturitiesOverall ?? [],
        });
      return {
        title: "IAM Governance Trends",

        currentScale: state.trendFilter?.gov.trendScale,
        currentYear:
          state.trendFilter?.gov.trendYear ?? new Date().getFullYear(),
        startDate: PROJECT_START_DATE,
        endDate: PROJECT_END_DATE,
        series: [
          {
            id: "actual",
            label: "Actual",
            data:
              state.currentTrends?.gov.actuals.map((val) =>
                val ? roundToTheNearestTenth(val) : val
              ) ??
              actuals.map((val) => (val ? roundToTheNearestTenth(val) : val)),
            curve: "linear",
          },
          {
            id: "planned",
            label: "Planned",
            data:
              state.currentTrends?.gov.planned.map((val) =>
                val ? roundToTheNearestTenth(val) : val
              ) ??
              planned.map((val) => (val ? roundToTheNearestTenth(val) : val)),
            curve: "linear",
          },
          {
            id: "recommended",
            label: "Minimum Target",
            data:
              state.currentTrends?.gov.recommended?.map((val) =>
                val ? roundToTheNearestTenth(val) : val
              ) ??
              recommended.map((val) =>
                val ? roundToTheNearestTenth(val) : val
              ),
            curve: "linear",
            connectNulls: true,
            /** index equal to 10 because in case of monthly only November (the 10 month) should have a dot */
            showMark: ({ index }) =>
              state.trendFilter?.gov.trendScale === "monthly"
                ? index === 10
                : true,
          },
        ],
      };
    });

    govLineChart.on("change-time", (event) => {
      const payload = event.payload as LineChartCardEventMap["change-time"];
      const state = this.app?.getAppState();

      if (!payload || !state || !payload.scale || !payload.year) {
        return;
      }
      this.app?.setAppState({
        ...state,
        trendFilter: {
          ...(state.trendFilter ?? {
            model: {
              trendScale: "monthly",
              trendYear: new Date().getFullYear(),
            },
            overall: {
              trendScale: "monthly",
              trendYear: new Date().getFullYear(),
            },
            tech: {
              trendScale: "monthly",
              trendYear: new Date().getFullYear(),
            },
          }),

          gov: { trendScale: payload.scale, trendYear: payload.year },
        },
        currentTrends: {
          ...(state.currentTrends ?? {
            overall: { actuals: [], planned: [] },
            model: { actuals: [], planned: [] },
            tech: { actuals: [], planned: [] },
          }),
          gov: generateTrendEntriesFromCalculatedTrends({
            scale: payload.scale,
            year: payload.year,
            calculatedActuals: state.dashboard?.trends.govActuals ?? [],
            calculatedPlanned: state.dashboard?.trends.govPlanned ?? [],
            calculatedRecommended:
              state.dashboard?.trends.recommendedMaturitiesOverall ?? [],
          }),
        },
      });
    });

    const techLineChart = view.addComponent({
      component: LineChartCard,
      layoutProps: { xs: 4 },
    });

    techLineChart.mapState((state) => {
      const { actuals, planned, recommended } =
        generateTrendEntriesFromCalculatedTrends({
          scale: "monthly",
          year: new Date().getFullYear(),
          calculatedActuals: state.dashboard?.trends.techActuals ?? [],
          calculatedPlanned: state.dashboard?.trends.techPlanned ?? [],
          calculatedRecommended:
            state.dashboard?.trends.recommendedMaturitiesOverall ?? [],
        });
      return {
        title: "IAM Technology Trends",
        currentScale: state.trendFilter?.tech.trendScale,
        currentYear:
          state.trendFilter?.tech.trendYear ?? new Date().getFullYear(),
        startDate: PROJECT_START_DATE,
        endDate: PROJECT_END_DATE,
        series: [
          {
            id: "actual",
            label: "Actual",
            data:
              state.currentTrends?.tech.actuals.map((val) =>
                val ? roundToTheNearestTenth(val) : val
              ) ??
              actuals.map((val) => (val ? roundToTheNearestTenth(val) : val)),
            curve: "linear",
          },
          {
            id: "planned",
            label: "Planned",
            data:
              state.currentTrends?.tech.planned.map((val) =>
                val ? roundToTheNearestTenth(val) : val
              ) ??
              planned.map((val) => (val ? roundToTheNearestTenth(val) : val)),
            curve: "linear",
          },
          {
            id: "recommended",
            label: "Minimum Target",
            data:
              state.currentTrends?.tech.recommended?.map((val) =>
                val ? roundToTheNearestTenth(val) : val
              ) ??
              recommended.map((val) =>
                val ? roundToTheNearestTenth(val) : val
              ),
            curve: "linear",
            connectNulls: true,
            /** index equal to 10 because in case of monthly only November (the 10 month) should have a dot */
            showMark: ({ index }) =>
              state.trendFilter?.tech.trendScale === "monthly"
                ? index === 10
                : true,
          },
        ],
      };
    });

    techLineChart.on("change-time", (event) => {
      const payload = event.payload as LineChartCardEventMap["change-time"];
      const state = this.app?.getAppState();

      if (!payload || !state || !payload.scale || !payload.year) {
        return;
      }
      this.app?.setAppState({
        ...state,
        trendFilter: {
          ...(state.trendFilter ?? {
            model: {
              trendScale: "monthly",
              trendYear: new Date().getFullYear(),
            },
            overall: {
              trendScale: "monthly",
              trendYear: new Date().getFullYear(),
            },
            gov: { trendScale: "monthly", trendYear: new Date().getFullYear() },
          }),
          tech: { trendScale: payload.scale, trendYear: payload.year },
        },
        currentTrends: {
          ...(state.currentTrends ?? {
            overall: { actuals: [], planned: [] },
            gov: { actuals: [], planned: [] },
            model: { actuals: [], planned: [] },
          }),
          tech: generateTrendEntriesFromCalculatedTrends({
            scale: payload.scale,
            year: payload.year,
            calculatedActuals: state.dashboard?.trends.techActuals ?? [],
            calculatedPlanned: state.dashboard?.trends.techPlanned ?? [],
            calculatedRecommended:
              state.dashboard?.trends.recommendedMaturitiesOverall ?? [],
          }),
        },
      });
    });
    oeProgressLineChart.on("toggle-dimensions", (event) => {
      const payload =
        event.payload as LineChartCardEventMap["toggle-dimensions"];
      const state = this.app?.getAppState();

      if (!payload || !state) {
        return;
      }
      this.app?.setAppState({
        ...this.app.getAppState(),
        showDimensionCharts: payload.showDimensions,
      });
    });
    govLineChart.mapVisibility((state) => {
      if (!state) {
        return false;
      }

      return state.showDimensionCharts ?? false;
    });

    modelLineChart.mapVisibility((state) => {
      if (!state) {
        return false;
      }

      return state.showDimensionCharts ?? false;
    });

    techLineChart.mapVisibility((state) => {
      if (!state) {
        return false;
      }

      return state.showDimensionCharts ?? false;
    });

    const overallActivitiesTitle = view.addComponent({
      component: SectionHeader,
      layoutProps: {
        xs: 12,
      },
    });

    overallActivitiesTitle.mapState((state) => {
      const {
        numberOfActivities,
        numberOfCompletedActivities,
        percentageOfCompletedActivities,
      } = state.dashboard ?? {};
      return {
        title: `Overall Activities:`,
        chipColor: IAM_COLORS.babyBlue,
        textColor: "#fff",
        badgeTextContent: `${numberOfCompletedActivities ?? "n/a"}/${
          numberOfActivities ?? "n/a"
        } (${
          percentageOfCompletedActivities != null
            ? `${percentageOfCompletedActivities} %`
            : "n/a"
        })`,
      };
    });

    const progressCard = view.addComponent({
      component: InformationCard,
      layoutProps: {
        xs: 6,
      },
    });

    progressCard.mapState((state) => {
      if (!state.dashboard) {
        return {
          title: "Roll Out Overview - Completed Activities: n/a",
        };
      }
      const {
        numberOfActivities,
        numberOfCompletedActivities,
        percentageOfCompletedActivities,
      } = state.dashboard ?? {};
      return {
        title: "Roll Out Overview - Completed Activities",
        progressBars: [
          {
            title: "Total",
            actualValue: `${numberOfCompletedActivities}/${numberOfActivities} (${Math.round(
              percentageOfCompletedActivities
            )} %)`,
            progress: percentageOfCompletedActivities,
          },
          ...DIMENSIONS.map((dimension) => {
            const { completed, overall, percentageCompleted } =
              state.dashboard!.completedActivitiesPerDimensionAndSubDimension[
                dimension
              ];

            return {
              title: `${dimension}`,
              actualValue: `${completed}/${overall} (${Math.round(
                percentageCompleted
              )} %)`,
              progress: percentageCompleted,
            };
          }),
        ],
      };
    });

    const combinedActivityStatusCard = view.addComponent({
      component: CombinedActivityStatusCard,
      layoutProps: {
        xs: 6,
      },
    });

    combinedActivityStatusCard.mapState((state) => {
      const numberOfActivitiesPerStatus = state.dashboard
        ?.numberOfActivitiesPerStatus ?? {
        "at risk": 0,
        "not started": 0,
        "on track": 0,
        completed: 0,
      };

      const numberOfOverdueActivities: number =
        state.dashboard?.numberOfOverdueActivities ?? 0;

      const completedActivities: number =
        state.dashboard?.overallAverageActivityProgress ?? 0;

      const statusesToDisplay: ActivityStatus[] = [
        "not started",
        "at risk",
        "on track",
        "completed",
      ];

      return {
        donutChartProps: {
          cardProps: {
            sx: {
              flex: 1,
            },
          },
          title: "Status of Activities",
          series: statusesToDisplay.map(
            (status) => numberOfActivitiesPerStatus[status]
          ),
          backgroundColor: ["#eeeeee", "#FAB600", "#00C853", "#003781"],
          legendIcons: ["", "", "", `checkmark-squared-outlined.svg`],
          legendPosition: "left",
          labels: statusesToDisplay.map((status) => capitalize(status)),
          toolTipLabels: statusesToDisplay.map((status) => capitalize(status)),
          toolTipEnabled: true,
        },
        numberCardProps: {
          iconSrc: "calendar.svg",
          number: numberOfOverdueActivities,
          title: "Activities Overdue",
        },
        progressCardProps: {
          cardTitle: "Activities",
          cardIcon: "activities.svg",
          color: IAM_COLORS.babyBlue,
          value: completedActivities,
          displayValue: `${Math.round(completedActivities)} %`,
          label: "Progress",
        },
      };
    });

    const projectInformationTitle = view.addComponent({
      component: SectionHeader,
      layoutProps: {
        xs: 12,
      },
    });

    projectInformationTitle.mapState(() => ({
      title: "Project Information",
    }));

    const keyInformationCard = view.addComponent({
      component: KeyInformationCard,
      layoutProps: {
        xs: 6,
      },
    });

    keyInformationCard.mapState((state) => {
      const {
        averageOEIAMGovernanceFTEs = 0,
        totalOEIAMGovernanceFTEs = 0,
        averageOEIAMBusinessOperationsFTEs = 0,
        totalOEIAMBusinessOperationsFTEs = 0,
        totalLocalITAssetsOnboardedToIAMTooling = 0,
        totalPercentageOfAuthorizationConceptsForITAssets = 0,
        overallIAMChangeBudget = 0,
        totalItAssets = 0,
      } = state.dashboard ?? {};

      return {
        title: "Key Information",
        entries: [
          {
            label: "# of IAM Governance FTEs (Average per OE)",
            value: averageOEIAMGovernanceFTEs,
          },
          {
            label: "# of IAM Governance FTEs (Total)",
            value: totalOEIAMGovernanceFTEs,
          },
          {
            label: "# of IAM Business Operations FTEs (Average per OE)",
            value: averageOEIAMBusinessOperationsFTEs,
          },
          {
            label: "# of IAM Business Operations FTEs (Total)",
            value: totalOEIAMBusinessOperationsFTEs,
          },
          {
            label: "# of Local IT Assets (Total)",
            value: totalItAssets,
          },
          {
            label: "# of Local IT Assets Onboarded to IAM Tooling (Total)",
            value: totalLocalITAssetsOnboardedToIAMTooling,
          },
          {
            label:
              "% of IT Assets that have an Authorization Concept which is regularly reviewed and updated",
            value: `${totalPercentageOfAuthorizationConceptsForITAssets} %`,
          },
          {
            label: "IAM Change Budget 2024 (€)",
            value: overallIAMChangeBudget.toLocaleString() ?? "n/a",
          },
        ],
      };
    });

    const teamCard = view.addComponent({
      component: TeamCard,
      layoutProps: {
        xs: 6,
      },
    });

    teamCard.mapState((state) => {
      const { team } = state.program?.data.attributes ?? {};

      return {
        team: {
          teamMembers:
            team?.map((teamMember) => {
              const { name } = teamMember?.person?.data?.attributes ?? {};
              const { name: roleName } =
                teamMember?.role?.data?.attributes ?? {};

              return {
                fullName: name ?? "n/a",
                role: roleName ?? "n/a",
                chipColor: IAM_COLORS.babyBlue,
              };
            }) ?? [],
        },
        title: "IAM Functions",
      };
    });
  }
}
