import {
  MuiLineChartCard,
  QUARTERS,
  type MuiLineChartCardProps,
  MONTHS,
  getAllYearsBetweenDates,
} from "@pimo/pimo-components";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import {
  Box,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  type SelectChangeEvent,
  capitalize,
} from "@mui/material";
import {
  NavigateBeforeRounded,
  NavigateNextRounded,
} from "@mui/icons-material";

export type LineChartCardProps = MuiLineChartCardProps & {
  currentScale?: "yearly" | "monthly" | "quarterly";
  currentYear: number;
  /** can toggle visibility, i.e is the main chart of a group of charts and thus can control if other cards are visible */
  canToggleVisibilty?: boolean;
  startDate: Date;
  endDate: Date;
};

export type LineChartCardEventMap = {
  "change-time": {
    scale: "yearly" | "monthly" | "quarterly";
    year: number;
  };
  "toggle-dimensions": {
    showDimensions: boolean;
  };
};
export type LineChartCardEvent = keyof LineChartCardEventMap;
export type LineChartCardEventPayload =
  LineChartCardEventMap[LineChartCardEvent];

export const LineChartCard: PimoReactComponent<
  LineChartCardProps,
  LineChartCardEvent,
  LineChartCardEventPayload
> = ({
  canToggleVisibilty,
  currentScale = "monthly",
  currentYear,
  endDate,
  startDate,
  fireEvent,
  ...props
}) => {
  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    fireEvent?.("toggle-dimensions", { showDimensions: event.target.checked });
  };
  const handleChange = (event: SelectChangeEvent) => {
    fireEvent?.("change-time", {
      year: currentYear,
      scale: event.target.value as "yearly" | "monthly" | "quarterly",
    });
  };

  return (
    <MuiLineChartCard
      {...props}
      cardProps={{
        ...props.cardProps,
        rightSlot: canToggleVisibilty && (
          <FormControlLabel
            value="start"
            control={<Switch color="primary" onChange={handleToggleChange} />}
            label="Show Dimensions"
            labelPlacement="start"
          />
        ),
      }}
      xAxis={LINE_CHART_X_AXISES[currentScale]({
        currentScale,
        yearOptions: getAllYearsBetweenDates(
          startDate.getFullYear(),
          endDate.getFullYear()
        ),
      })}
      yAxis={LINE_CHART_Y_AXIS}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box sx={{ minWidth: 120 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
            {currentScale !== "yearly" && (
              <Box sx={{ display: "flex", alignItems: "center", gap: 0 }}>
                <NavigateBeforeRounded
                  sx={{
                    cursor: currentYear === startYear ? "auto" : "pointer",
                    color: currentYear === startYear ? "lightgray" : "black",
                  }}
                  onClick={() => {
                    if (currentYear === startYear) {
                      return;
                    }
                    fireEvent?.("change-time", {
                      year: currentYear - 1,
                      scale: currentScale,
                    });
                  }}
                />
                <Box>{currentYear}</Box>
                <NavigateNextRounded
                  sx={{
                    cursor: currentYear === endYear ? "auto" : "pointer",
                    color: currentYear === endYear ? "lightgray" : "black",
                  }}
                  onClick={() => {
                    if (currentYear === endYear) {
                      return;
                    }
                    fireEvent?.("change-time", {
                      year: currentYear + 1,
                      scale: currentScale,
                    });
                  }}
                />
              </Box>
            )}
            <FormControl fullWidth>
              <Select
                value={currentScale}
                onChange={handleChange}
                sx={{
                  width: 120,
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
              >
                {SCALES.map((scale) => (
                  <MenuItem key={scale.value} value={scale.value}>
                    {scale.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
    </MuiLineChartCard>
  );
};

const LINE_CHART_X_AXISES = {
  yearly: ({
    currentScale,
    yearOptions,
  }: {
    currentScale: LineChartCardEventMap["change-time"]["scale"];
    yearOptions: number[];
  }) => [
    {
      tickSize: 1,
      tickMaxStep: 1,
      tickMinStep: 1,
      label: capitalize(currentScale),
      data: [0, 1, 2, 3, 4, null, null, null, null, null, null, null],
      valueFormatter: (val: number) =>
        !isNaN(val) ? yearOptions[val].toString() : "",
    },
  ],
  quarterly: ({
    currentScale,
  }: {
    currentScale: LineChartCardEventMap["change-time"]["scale"];
  }) => [
    {
      tickSize: 1,
      tickMaxStep: 1,
      tickMinStep: 1,
      label: capitalize(currentScale),
      data: [0, 1, 2, 3, null, null, null, null, null, null, null, null],
      valueFormatter: (val: number) => QUARTERS[val],
    },
  ],
  monthly: () => [
    {
      tickSize: 1,
      tickMaxStep: 1,
      tickMinStep: 1,
      label: "Months",
      data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      hideTooltip: true,
      valueFormatter: (val: number) => MONTHS[val]?.substring(0, 3) ?? "",
    },
  ],
};

const LINE_CHART_Y_AXIS = [
  {
    min: 0.5,
    max: 5.5,
    tickSize: 1,
    tickMaxStep: 1,
    tickMinStep: 1,
    label: "Maturity",
  },
];

const SCALES = [
  { value: "yearly", label: "Yearly" },
  { value: "quarterly", label: "Quarterly" },
  { value: "monthly", label: "Monthly" },
] as const;
